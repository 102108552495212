const  ph =  {
 translation: {
  "text_custom_range": "Custom range",
  "text_last_month": "Last month",
  "text_last_n_day": "Last {{n}} days",
  "text_this_month": "This month",
  "text_today": "Today",
  "text_yesterday": "Yesterday"
} 
} 
export default ph
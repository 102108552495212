import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit';
import { sidebarReducer } from '@components/core/Sidebar/sidebarSlice';
import TiktokAffReducer from '@features/tiktok-affiliate/tiktokAffiliateSlice';
import { tourReducer } from '@components/layout/Tour/tourSlice';

export function makeStore() {
  return configureStore({
    reducer: {
      sidebar: sidebarReducer,
      tiktokAffiliate: TiktokAffReducer,
      tour: tourReducer,
    },
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware({
        serializableCheck: false,
      });
    },
  });
}

const store = makeStore();

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;

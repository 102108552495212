import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
export const TOUR_SLICE_NAME = 'tour_slice';
interface ITour {
  open: boolean;
  isWelcome: boolean;
}

const initialState: ITour = {
  open: false,
  isWelcome: false,
};

const tourSlice = createSlice({
  name: TOUR_SLICE_NAME,
  initialState,
  reducers: {
    setTourOpen(state: ITour, actions: PayloadAction<boolean>) {
      state.open = actions.payload;
    },
    setIsWelcome(state: ITour, actions: PayloadAction<boolean>) {
      state.isWelcome = actions.payload;
    },
  },
});

export const { setTourOpen, setIsWelcome } = tourSlice.actions;

export const selectStatusTour = (state: RootState) => state.tour.open;
export const selectStatusWelcomeTour = (state: RootState) => state.tour.isWelcome;

export const tourReducer = tourSlice.reducer;

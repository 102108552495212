import { SIDEBAR_SLICE_NAME } from '@components/layout/constants/sidebar';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface ISidebar {
  open: boolean;
  collapsed: boolean;
}

const initialState: ISidebar = {
  open: false,
  collapsed: false,
};

const sidebarSlice = createSlice({
  name: SIDEBAR_SLICE_NAME,
  initialState,
  reducers: {
    toggleSidebar(state: ISidebar, actions: PayloadAction<boolean>) {
      state.open = actions.payload;
    },
    toggleCollapseSidebarDesktop(state: ISidebar, actions: PayloadAction<boolean>) {
      state.collapsed = actions.payload;
    },
  },
});

export const { toggleSidebar, toggleCollapseSidebarDesktop } = sidebarSlice.actions;

export const selectStatusSidebar = (state: RootState) => state.sidebar.open;
export const selectStatusCollapsed = (state: RootState) => state.sidebar.collapsed;

export const sidebarReducer = sidebarSlice.reducer;

// Link figma: https://www.figma.com/file/ygDhVZBTVWL6tHEpJN3Rzg/Metronic8.1?node-id=8592%3A2668&t=Ox9CVSNC6aOlme43-0

export enum APP_COLORS {
  PASSIO = '#EE3244',

  PRIMARY = '#ee3446',
  PRIMARY_ACTIVE = '#2884EF',
  PRIMARY_LIGHT = '#f1faff',

  SUCCESS = '#50CD89',
  SUCCESS_ACTIVE = '#47BE7D',
  SUCCESS_LIGHT = '#E8FFF3',

  DANGER = '#F1416C',
  DANGER_ACTIVE = '#D9214E',
  DANGER_LIGHT = '#FFF5F8',

  INFO = '#7239EA',
  INFO_ACTIVE = '#5014D0',
  INFO_LIGHT = '#F8F5FF',

  WARNING = '#FFC700',
  WARNING_ACTIVE = '#E9B500',
  WARNING_LIGHT = '#FFF8DD',

  WHITE = '#FFFFFF',
  WHITE_ACTIVE = '#F9F9F9',

  DARK = '#181C32',

  TEXT_COLOR = '#181C32',
}

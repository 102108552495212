import { CSSProperties } from 'react';

export type VariantsInput = 'outlined' | 'filled';

// defined default input styles
type PartialRecord<K extends string | number | symbol, T> = { [P in K]?: T };

export const APP_INPUTS: PartialRecord<VariantsInput, CSSProperties> = {
  outlined: {
    // borderColor: '#E1E3EA',
    // borderWidth: 1,
  },
  filled: {
    borderColor: 'transparent',
    background: '#f5f8fa',
  },
};

export const APP_INPUT_HEIGHT = 32;

import { CSSProperties } from 'react';

// Link figma: https://www.figma.com/file/ygDhVZBTVWL6tHEpJN3Rzg/Metronic8.1?node-id=8592%3A2668&t=Ox9CVSNC6aOlme43-0

// declare type for text
export type Variants =
  | 'heading1'
  | 'heading2'
  | 'heading3'
  | 'heading4'
  | 'heading5'
  | 'heading6'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'body5'
  | 'body6'
  | 'body7'
  | 'body8'
  | 'body9'
  | 'body10'
  | 'body11'
  | 'body12'
  | 'body13';

// defined default text styles
type PartialRecord<K extends string | number | symbol, T> = { [P in K]?: T };

export const APP_TEXTS: PartialRecord<Variants, CSSProperties> = {
  heading1: {
    fontWeight: 700,
    fontSize: 38,
    letterSpacing: -2,
    lineHeight: '70px',
  },
  heading2: {
    fontWeight: 700,
    fontSize: 34,
    letterSpacing: -2,
    lineHeight: '53px',
  },
  heading3: {
    fontWeight: 700,
    fontSize: 30,
    letterSpacing: -2,
    lineHeight: '47px',
  },
  heading4: {
    fontWeight: 700,
    fontSize: 26,
    letterSpacing: -2,
    lineHeight: '41px',
  },
  heading5: {
    fontWeight: 700,
    fontSize: 22,
    letterSpacing: -1,
    lineHeight: '35px',
  },
  heading6: {
    fontWeight: 700,
    fontSize: '20px',
    letterSpacing: -1,
    lineHeight: '32px',
  },
  body1: {
    fontWeight: 600,
    fontSize: 18,
    letterSpacing: -1,
    lineHeight: '32px',
  },
  body2: {
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '26px',
  },
  body3: {
    fontWeight: 600,
    fontSize: 15,
    lineHeight: '24px',
  },
  body4: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '23px',
  },
  body5: {
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '23px',
  },
  body6: {
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '23px',
  },
  body7: {
    fontWeight: 500,
    fontSize: 13,
    lineHeight: '22px',
  },
  body8: {
    fontWeight: 600,
    fontSize: 13,
    lineHeight: '22px',
  },
  body9: {
    fontWeight: 700,
    fontSize: 13,
    lineHeight: '22px',
  },
  body10: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '20px',
  },
  body11: {
    fontWeight: 600,
    fontSize: 12,
    lineHeight: '20px',
  },
  body12: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '20px',
  },
  body13: {
    fontWeight: 600,
    fontSize: 10,
    letterSpacing: -3,
    lineHeight: '18px',
  },
};

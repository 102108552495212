import { Variants } from '@components/shared/app-texts';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_TEXTS } from '@components/shared/app-texts';
import { APP_COLORS } from '@components/shared/app-colors';
import { APP_PREFIX_TRANSLATION_KEY } from '@constants/app';

/**
 * Example
 * <TranslatedText translationKey="this_is_my_key" />
 * ====> output: Key translated
 * ===== or =====
 * <TranslatedText translationKey="simple_value_here" valuesTranslation={{my_value: 3}} />
 * input: My value is {{my_value}} here
 * output: My value is 3 here
 **/

type IProps = {
  translationKey: string | number;
  style?: CSSProperties;
  className?: string;
  variant?: Variants;
  valuesTranslation?: Record<string, any>; // Dynamic values to be interpolated into the translation string
  useAppPrefixKey?: boolean;
};

const TranslatedText = ({
  translationKey,
  valuesTranslation,
  style,
  className = '',
  variant = 'body2',
  useAppPrefixKey = true,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <p className={`mb-0 ${className}`} style={{ ...APP_TEXTS[variant], color: APP_COLORS.TEXT_COLOR, ...style }}>
      {t(
        useAppPrefixKey ? APP_PREFIX_TRANSLATION_KEY + translationKey?.toString() : translationKey?.toString(),
        valuesTranslation || {},
      )}
    </p>
  );
};

export default TranslatedText;

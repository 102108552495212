const  th =  {
 translation: {
  "BS_CLICK": "Click",
  "BS_CPO": "CPO",
  "BS_CVR": "CVR",
  "BS_ITEM_PER_ORDER": "Item per order",
  "BS_NO": "No",
  "BS_TOTAL_ORDER": "Total orders",
  "BS_UNIQUE_CLICK": "Unique click",
  "billion": "Billion",
  "bs_1_step_left": "เหลืออีก 1 ก้าว",
  "bs_ICT": "ICT",
  "bs_KOC": "KOC ({{total}})",
  "bs_about_me_title_1": "We proud to be one of the top 5 Tiktok Multi-Channel Networks (MCN) and strategic partner of the largest e-commerce marketplace in Southeast Asia.",
  "bs_about_me_title_2": "We possess more than 2000+ exclusive influencers and content creators and a community of more than 500K content creators from multi-niche, cross 6 markets.",
  "bs_about_me_title_3": "Time-efficiently with the always-on campaign. We possess campaign launching automation. Replacing manual process with payment workflow efficiently.",
  "bs_about_us": "About us",
  "bs_action_view_suggest_creator": "Suggested Creators",
  "bs_actions_crawl_data": "Crawl Data",
  "bs_active": "Active",
  "bs_active_status": "Active",
  "bs_add_item": "Add",
  "bs_add_key_test": "Key test",
  "bs_add_new_link": "Connect new brand",
  "bs_add_shop": "Add store",
  "bs_add_to_cart": "Add to cart",
  "bs_add_to_kol_list": "Add to KOL list",
  "bs_added_cart": "Added",
  "bs_advertiser": "Advertiser",
  "bs_age": "Age",
  "bs_all_brand": "Select all Brand",
  "bs_all_sku": "Select all SKU",
  "bs_allow_all_sku": "Applies to all SKU",
  "bs_allow_all_sub_brands": "Applies to all Sub-brands",
  "bs_already_have_account": "คุณมีบัญชีอยู่แล้ว",
  "bs_aov": "AOV",
  "bs_aov_tooltip_content": "Average order value = GMV Actual / Total Order",
  "bs_approve": "Approve",
  "bs_as": "As",
  "bs_beauty": "Beauty and Cosmetics",
  "bs_billion": "Billion",
  "bs_blog": "Blog",
  "bs_brand_affiliate_actual_commission": "Actual commission",
  "bs_brand_affiliate_comment": "Comments",
  "bs_brand_affiliate_commission": "Estimated commission",
  "bs_brand_affiliate_compare_last_period": "compared to the previous",
  "bs_brand_affiliate_content_video_tiktok": "Video content",
  "bs_brand_affiliate_cps_report": "Overview",
  "bs_brand_affiliate_cps_report_text": "Connect shop and set up KPIs to complete dashboard",
  "bs_brand_affiliate_dashboard_title": "Brand Afiliate",
  "bs_brand_affiliate_engagement": "Engagements",
  "bs_brand_affiliate_gmv": "GMV",
  "bs_brand_affiliate_label_last_update": "Last updated",
  "bs_brand_affiliate_label_list_live": "List live",
  "bs_brand_affiliate_label_live_hour_by_day": "Number of hours live each day",
  "bs_brand_affiliate_label_main_index": "Key metrics",
  "bs_brand_affiliate_label_ranking": "Charts",
  "bs_brand_affiliate_label_time_view_data": "Time",
  "bs_brand_affiliate_label_top_5_duration": "Top 5 hardworking",
  "bs_brand_affiliate_label_top_5_expert": "Top 5 Livestream Experts",
  "bs_brand_affiliate_label_top_5_kol": "Top 5 KOLs",
  "bs_brand_affiliate_label_top_5_order": "Top 5 sales",
  "bs_brand_affiliate_label_top_5_product": "Top 5 products",
  "bs_brand_affiliate_label_top_5_revenue": "Top 5 sales",
  "bs_brand_affiliate_label_top_5_sub_brand": "Top 5 Sub Brand",
  "bs_brand_affiliate_label_top_live_stream": "Top livestream",
  "bs_brand_affiliate_label_top_platform": "Platform",
  "bs_brand_affiliate_label_trend_chart_each_index": "Trend chart for each index",
  "bs_brand_affiliate_label_view_all": "View all",
  "bs_brand_affiliate_like": "Likes",
  "bs_brand_affiliate_live_stream": "Total livestream",
  "bs_brand_affiliate_new_user": "New buyer",
  "bs_brand_affiliate_option_last_1_month": "Last month",
  "bs_brand_affiliate_option_last_7_days": "Last 7 days",
  "bs_brand_affiliate_option_last_week": "Last Weeks",
  "bs_brand_affiliate_option_this_month": "This month",
  "bs_brand_affiliate_option_this_quarter": "Quarter",
  "bs_brand_affiliate_option_this_week": "This week",
  "bs_brand_affiliate_option_today": "Today",
  "bs_brand_affiliate_option_yesterday": "Yesterday",
  "bs_brand_affiliate_order": "Order",
  "bs_brand_affiliate_post": "Posts",
  "bs_brand_affiliate_product": "Product sold",
  "bs_brand_affiliate_roi": "ROI",
  "bs_brand_affiliate_save": "Saves",
  "bs_brand_affiliate_share": "Shares",
  "bs_brand_affiliate_total_host": "Total host",
  "bs_brand_affiliate_user": "All buyers",
  "bs_brand_connected": "Connected Brands",
  "bs_brand_insights": "Brand Detail",
  "bs_brand_logo": "Brand logo",
  "bs_brand_logo_required": "Brand logo is required",
  "bs_brand_name": "Brand name",
  "bs_brand_name_placeholder": "Enter brand name",
  "bs_brands": "Brands",
  "bs_brands_booking": "Brands Booking",
  "bs_brands_booking_listing": "Campaign list",
  "bs_button_add_product_sample": "Enabel Product Sample",
  "bs_button_apply": "Apply",
  "bs_button_content_write_review": "Write a review",
  "bs_button_continue": "Continue",
  "bs_button_copy_manual": "Copy instructions",
  "bs_button_create_host": "Create host",
  "bs_button_create_request": "Create request",
  "bs_button_create_room": "Create live room",
  "bs_button_deselect_all": "Deselect all",
  "bs_button_export_data": "Export Data",
  "bs_button_finish": "Finish",
  "bs_button_import_sale_performance": "Import Data",
  "bs_button_ok_popup_confirm_save_kol": "Agree",
  "bs_button_reset": "Reset",
  "bs_button_search": "Search",
  "bs_button_skip": "Skip",
  "bs_button_start": "Get Start",
  "bs_button_start_discovery": "Start exploring",
  "bs_button_submit": "Submit",
  "bs_button_sync_orders": "Sync Orders",
  "bs_button_try_again": "Retry",
  "bs_buy_video_now": "Buy video now",
  "bs_calendar_host_register": "Host schedule registered",
  "bs_calendar_scheduled": "Live schedule set",
  "bs_campaign": "Campaign",
  "bs_campaign_error_platform_link": "The submit link is invalid",
  "bs_campaign_error_type__channel_link": "Link channel is not in correct format",
  "bs_campaign_error_type_link": "Link submit is not in correct format",
  "bs_campaign_info": "Campaign Info",
  "bs_campaign_list_title": "List campaign",
  "bs_campaign_name": "Campaign Name",
  "bs_campaign_name_is_required": "The Campaign name field is required",
  "bs_campaign_name_placeholder": "Enter campaign name",
  "bs_campaign_report": "Campaign Report",
  "bs_campaign_report_total_koc_joined": "KOC Joined",
  "bs_campaign_report_unit_engagements": "Engagements",
  "bs_campaign_report_unit_posts": "Posts",
  "bs_campaign_report_unit_views": "Views",
  "bs_campaign_reports": "Campaign Reports",
  "bs_campaign_search_status_title": "Status",
  "bs_campaign_search_time_title": "Time",
  "bs_campaign_search_title": "Campagin",
  "bs_campaign_send_invitation_successfully": "Invitation sent successfully",
  "bs_campaign_send_mail_successfully": "Email has been sent successfully",
  "bs_campaign_title_egagement": "Egagement",
  "bs_campaign_title_perfomence_count": "Performance",
  "bs_campaign_title_sale_performence": "Sales Performance",
  "bs_campaigns": "Campaigns",
  "bs_campaigns_overview": "Campaigns Overview",
  "bs_cancel": "Cancel",
  "bs_cancel_edit": "Cancel Edit",
  "bs_cancel_list_footer": "Back",
  "bs_cancel_list_kol": "Cancel",
  "bs_cancel_live": "Cancel livestream",
  "bs_cancel_popup_confirm": "Cancel",
  "bs_cancel_session": "Cancel Live session",
  "bs_canceled": "Canceled",
  "bs_case_study": "Case Study",
  "bs_categories": "Categories",
  "bs_category": "Category",
  "bs_change_live_time": "Change live time",
  "bs_change_live_time_failed": "Changing the live schedule failed",
  "bs_change_live_time_failed_pending_request": "New requests cannot be created while requests are pending",
  "bs_change_live_time_failed_same_time": "There is a live session at this time",
  "bs_change_password": "เปลี่ยนรหัสผ่าน",
  "bs_checkin_failed": "Check-in failed",
  "bs_checkin_image": "Upload check-in image",
  "bs_choice_live_session": "Select live session",
  "bs_choose_jobs": "Select Categories",
  "bs_choose_jobs_placeholder": "-Select Categories-",
  "bs_click_total": "Total Click",
  "bs_clicks": "Clicks",
  "bs_close": "Close",
  "bs_collapse": "Collapse",
  "bs_column_total_content": "Total Livestream/Video",
  "bs_commission": "Commission",
  "bs_commission_actual": "Commission Actual",
  "bs_comprehensive_tracking_content": "Transparent and detailed measurement system to track & optimize performance",
  "bs_comprehensive_tracking_description": "Transparent, detailed and real-time measurement system helps you see how your campaigns perform across any channels – KOLs/ Creators – Websites… ",
  "bs_comprehensive_tracking_title": "Comprehensive Tracking",
  "bs_comprehensive_tracking_title_1": "End-to-end Performance Monitor (Order/ Sales/ Comission...)",
  "bs_comprehensive_tracking_title_2": "Tracking Traffic (Campaign/ Source/ KOL...)",
  "bs_comprehensive_tracking_title_3": "Real-time & Transparent Data (Data Export/ Data Dashboard For Website Or Store Online...)",
  "bs_confirm": "Confirm",
  "bs_confirm_cancel_campaign": "Are you sure you want to cancel the campaign?",
  "bs_confirm_cancel_list_kol": "Are you sure you want to cancel the KOC selection?",
  "bs_confirm_cancel_session": "Are you sure you want to cancel this live session?",
  "bs_confirm_cancel_session_live_success": "Live session canceled successfully",
  "bs_confirm_cancel_session_title": "Information has not been saved. Are you sure you want to cancel?",
  "bs_confirm_popup_confirm": "Send request",
  "bs_confirm_uncheck_kol": "Are you sure you want to unselect this KOC?",
  "bs_connect": "Connect",
  "bs_connect_shop": "Reconnect",
  "bs_connecting": "Connecting",
  "bs_content_message_create_sku_success": "Create new products successfully",
  "bs_content_message_edit_sku_success": "Edited successfully",
  "bs_content_message_export_error_limit_data_row": "The system only supports a maximum of 5000 records per data export. Please filter data before exporting.",
  "bs_content_message_export_success": "Export Complete",
  "bs_content_popup_confirm_delete": "Are you sure you want to delete this record?",
  "bs_content_popup_confirm_delete_campagin": "Are you sure you want to delete {{name}}? This action cannot be undone.",
  "bs_content_popup_confirm_delete_multiple": "Are you sure you want to delete this {{num}} link result?",
  "bs_content_popup_confirm_delete_multiple_sku": "Are you sure you want to delete this {{num}} SKU? This action cannot be undone.",
  "bs_content_popup_confirm_delete_multiple_tiktok": "Are you sure you want to delete {{num}} links?",
  "bs_content_popup_confirm_delete_sku": "Are you sure you want to delete this {{name}}? This action cannot be undone.",
  "bs_content_popup_confirm_delete_tiktok": "Are you sure you want to delete this link?",
  "bs_content_popup_confirm_save_kol": "Ecomobi will help you contact and work with selected KOCs.",
  "bs_continue": "Save",
  "bs_conversion_time": "Conversion Time",
  "bs_copied_text": "Copied",
  "bs_copy_text": "Copy link",
  "bs_cost": "Cost",
  "bs_cost_effective_content": "Competitive and flexible price models for many scales and demands of Brands",
  "bs_cost_effective_description": "Competitive, flexible price models suitable for many scales and demands of Brands. Less cost – more value is our operating principle.",
  "bs_cost_effective_title": "Cost - Effective",
  "bs_cost_effective_title_1": "Free System Initialization Fee",
  "bs_cost_effective_title_2": "Flexible Fee Package",
  "bs_cost_effective_title_3": "Advance and Billing",
  "bs_countries": "Countries",
  "bs_country": "Country",
  "bs_country_code": "Country",
  "bs_country_code_is_required": "The Country field is required",
  "bs_country_id": "Indonesia",
  "bs_country_my": "Malaysia",
  "bs_country_other": "Other",
  "bs_country_ph": "Philippines",
  "bs_country_sg": "Singapore",
  "bs_country_th": "Thailand",
  "bs_country_vn": "Vietnamese",
  "bs_cpo_tooltip_content": "Commission Per Order = Commission Actual / Total Order ",
  "bs_create_campaign": "Create Campaign",
  "bs_create_campaign_success": "Create Campaign successfully",
  "bs_create_host_title": "Create host",
  "bs_create_livestream": "Create Live session",
  "bs_create_livestream_label_day": "Create new live session for",
  "bs_create_livestream_placeholder": "Enter {{field}}",
  "bs_create_new": "Create",
  "bs_create_new_sku": "Add new",
  "bs_create_request": "Create request",
  "bs_create_room_title": "Create live room",
  "bs_create_sku": "Add new product",
  "bs_creator_connect_tooltip_nmv": "Successful orders",
  "bs_creators": "Creator",
  "bs_creators_az": "Creators A-Z",
  "bs_creators_joined": "Creators Joined",
  "bs_currency": "Currency:",
  "bs_cvr_tooltip_content": "Conversion Rate =Total Order / Unique Click ",
  "bs_dashboard_chart_title_active_post": "Number of KOC posts",
  "bs_dashboard_chart_title_engagement": "Interact",
  "bs_dashboard_chart_title_post": "Post",
  "bs_dashboard_data_engagements": "Interact",
  "bs_dashboard_data_koc_joined": "KOC joined",
  "bs_dashboard_data_posts": "Post",
  "bs_dashboard_data_views": "Views",
  "bs_dashboard_description_not_connect_shop": "Connect shop to uncover insight from this report",
  "bs_dashboard_login_title": "The dashboard is specially designed with the goal of",
  "bs_dashboard_page": "Dashboard",
  "bs_dashboard_placeholder_select_campaign": "- Select Campaign -",
  "bs_dashboard_sub_title_report_booking": "Report this month",
  "bs_dashboard_title": "Dashboard",
  "bs_dashboard_title_data_order_sale": "Sales Performance",
  "bs_dashboard_title_not_connect_shop": "No data source for this report",
  "bs_dashboard_title_overview_engagements": "Engagement Performance",
  "bs_dashboard_title_report_affiliate": "Report affiliate marketing effectiveness",
  "bs_dashboard_title_report_booking": "Report campaign effectiveness",
  "bs_dashboard_title_table_top_live": "Top Livestream",
  "bs_dashboard_title_table_top_video": "Top Video",
  "bs_day": "Day",
  "bs_day_selected": "Details date",
  "bs_delete": "Delete",
  "bs_delete_all": "Delete all",
  "bs_delete_popup": "Delete",
  "bs_delete_popup_confirm": "Confirm deletion",
  "bs_delivered": "Delivered",
  "bs_description_modal_connect_host": "Your account is not currently connected to any brand. Please enter the connect code received from the brand to track your live stream schedule.",
  "bs_detail": "Detail",
  "bs_detail_campaign": "Campaign Detail",
  "bs_detail_list_kol": "KOC List",
  "bs_detail_sku_title_table": "List creator contact",
  "bs_disconnect_shop": "Disconnect Shop",
  "bs_disconnected": "Disconnected",
  "bs_disconnecting": "Disconnecting",
  "bs_discovery_brand": "Discovery Brand",
  "bs_discovery_search_placeholder": "Search with brand name",
  "bs_discovery_text_GMV": "GMV",
  "bs_discovery_text_Orders": "Orders",
  "bs_discovery_text_Products": "Products",
  "bs_discovery_text_SALE": "SALE",
  "bs_discovery_text_SKUs": "SKUs",
  "bs_discovery_text_Sale_ucfist": "Sale",
  "bs_discovery_text_USD": "USD",
  "bs_discovery_text_Users": "Users",
  "bs_discovery_text_Videos": "Videos",
  "bs_discovery_text_influencer": "Influencers",
  "bs_discovery_text_live": "Sessions",
  "bs_discovery_text_relevant_influencer": "Relevant Influencer",
  "bs_discovery_text_relevant_live": "Relevant Live",
  "bs_discovery_text_video_by_brand": "Relevant Video",
  "bs_discovery_text_views": "Views",
  "bs_discovery_title_avg_view": "Most Average View",
  "bs_discovery_title_gmv": "Most GMV",
  "bs_discovery_title_relevant_influencer": "Most Relevant Influencer",
  "bs_discovery_title_sale": "Most Sale",
  "bs_download": "Download",
  "bs_download_file_error": "Download file error",
  "bs_download_file_warning": "Download file",
  "bs_easier_recruiting_content": "Access to 100.000+ KOLs/ Creators who certainly match Brands' campaigns",
  "bs_easier_recruiting_description": "Join us to access a vast pool of 100.000+ qualified KOLs/ Creators/ Influencers who certainly match your campaigns.",
  "bs_easier_recruiting_title": "Easier Recruiting",
  "bs_easier_recruiting_title_1": "100.000+ Qualified KOLs/ Creators",
  "bs_easier_recruiting_title_2": "Traffic Per Month",
  "bs_easier_recruiting_title_3": "20+ Various Brand Categories",
  "bs_easier_recruiting_title_4": "KOL/ Creator Profile",
  "bs_ecomerce": "E-Commerce",
  "bs_ecomobi_passio": "Ecomobi Passio",
  "bs_edit": "Edit",
  "bs_edit_connect_host_description": "Host is not linked to Passio account, please guide host to link account to follow his live session",
  "bs_edit_connect_host_title": "Account linking instructions",
  "bs_edit_host_title": "Edit host information",
  "bs_edit_livestream": "Edit Livestream",
  "bs_edit_livestream_title": "Edit live session",
  "bs_edit_room_title": "Edit live room",
  "bs_edit_sku": "Product details",
  "bs_edit_sku_content_popup_confirm_delete_sku": "Are you sure you want to delete this {{name}}? This action cannot be undone.",
  "bs_edit_sku_field_category": "Category",
  "bs_edit_sku_field_fb_sku_id": "Facebook product code",
  "bs_edit_sku_field_insta_sku_id": "Instagram product code",
  "bs_edit_sku_field_lzd_sku_id": "Lazada product code",
  "bs_edit_sku_field_main_sku_id": "Product code",
  "bs_edit_sku_field_product_img": "Image product",
  "bs_edit_sku_field_shopee_sku_id": "Shopee product code",
  "bs_edit_sku_field_sku_name": "Product's name",
  "bs_edit_sku_field_sub_brand_id": "Sub Brand",
  "bs_edit_sku_field_subsegment": "Subsegment",
  "bs_edit_sku_field_tiki_sku_id": "Tiki product code",
  "bs_edit_sku_field_tiktok_sku_id": "Tiktok product code",
  "bs_edit_sku_placeholder_field_category": "Select Category",
  "bs_edit_sku_placeholder_field_fb_sku_id": "For example: SPFB9999999",
  "bs_edit_sku_placeholder_field_insta_sku_id": "For example: SPIT9999999",
  "bs_edit_sku_placeholder_field_lzd_sku_id": "For example: SPLZD9999999",
  "bs_edit_sku_placeholder_field_main_sku_id": "Example: 9999999",
  "bs_edit_sku_placeholder_field_product_img": "Enter the image path",
  "bs_edit_sku_placeholder_field_shopee_sku_id": "For example: SPSP9999999",
  "bs_edit_sku_placeholder_field_sku_name": "Example: High quality shower gel",
  "bs_edit_sku_placeholder_field_sub_brand_id": "Select Sub-brand",
  "bs_edit_sku_placeholder_field_subsegment": "Import content",
  "bs_edit_sku_placeholder_field_tiki_sku_id": "For example: SPTK9999999",
  "bs_edit_sku_placeholder_field_tiktok_sku_id": "For example: SPTT9999999",
  "bs_edit_sku_title": "Product information",
  "bs_edit_sku_title_popup_confirm_delete_sku": "Delete this product?",
  "bs_education": "Education",
  "bs_ellana_cosmetic": "Ellana Cosmetic",
  "bs_ellana_cosmetic_content": "Ellana Cosmetic collaborates with KOLs/ Creators in Ecomobi Passio for attracting users to increase purchases in Ellana's Official Shopee Store. See what impressive performance per month Ellana gets for their brand!",
  "bs_email_not_exist": "อีเมลไม่มีอยู่",
  "bs_email_used": "อีเมลถูกใช้แล้ว",
  "bs_empty_campaign": "No entry campaign has been created yet",
  "bs_empty_data_influencer": "No Influencer data yet",
  "bs_empty_data_table": "No data",
  "bs_empty_in_table_brand_booking": "No data",
  "bs_empty_in_table_campaign": "No results yet",
  "bs_empty_in_table_list_video_job": "There is no request list yet",
  "bs_empty_in_table_tiktok_counter": "No data",
  "bs_empty_kol": "There are currently no KOLs that match your campaign",
  "bs_en": "English",
  "bs_end_date_label": "End date",
  "bs_english": "English",
  "bs_enter_field_name": "Enter {{field}}",
  "bs_entertainment": "Entertainment",
  "bs_error_add_category_existed": "The category already exists",
  "bs_error_add_category_required": "Please fill in the category name",
  "bs_error_add_sub_brand_existed": "The sub-brand already exists",
  "bs_error_add_sub_brand_required": "Please fill in the sub-brand name ",
  "bs_error_code_connect_passio": "The connect code is incorrect or has already been used. Please check with the brand.",
  "bs_error_field_category": "You have not selected a Category",
  "bs_error_field_main_sku_id": "You have not entered the Product Code",
  "bs_error_field_sku_name": "You have not entered a Product Name",
  "bs_error_field_sub_brand_id": "You have not selected a sub-brand",
  "bs_error_field_subsegment": "You entered too many characters. Please enter less than 255 characters!",
  "bs_error_host_is_using": "The live room is in use and cannot be deleted",
  "bs_error_host_name_existed": "This host name already existed",
  "bs_error_passio_username_wrong": "This username is wrong. Please check again",
  "bs_error_room_name_error": "Live room \"{{name}}\" already exists in Active status. Please check again",
  "bs_error_room_name_existed": "The room name already exists in the active state",
  "bs_error_row_import": "There are {{num}} invalid records in this file. Please check again",
  "bs_expert_support_content": "24/7 professional support team to assist Brands in getting the highest performance",
  "bs_expert_support_description": "Our 24/7 professional support team assists you in getting the highest performance through how-to guides and Sales-Marketing optimization.",
  "bs_expert_support_title": "Expert Support",
  "bs_expert_support_title_1": "1-1 Support With Our Expert Team",
  "bs_expert_support_title_2": "Experienced With 1.000+ Campaigns Per Year",
  "bs_expert_support_title_3": "Advice On Building Marketing & Sales Channels",
  "bs_export": "Export Report",
  "bs_facebook": "Facebook",
  "bs_fashion": "Fashion",
  "bs_fast_exactly_result": "Update implementation results and comprehensive reports accurately",
  "bs_female": "Female",
  "bs_field_brand_description_is_required": "This field is required",
  "bs_field_request_content_is_required": "This field is required",
  "bs_field_required": "This field is required",
  "bs_filter": "Filter",
  "bs_find_out_more": "Find out more",
  "bs_first": "first",
  "bs_float_button_user_manual": "User manual",
  "bs_fnb": "F&B",
  "bs_for_brands": "For Brands",
  "bs_for_creator": "For Creator",
  "bs_forgot_password": "Forgot Password?",
  "bs_forgot_password_description": "ป้อนที่อยู่อีเมลเพื่อรีเซ็ตรหัสผ่านของคุณ",
  "bs_forgot_password_failed_title": "ลืมรหัสผ่านล้มเหลว",
  "bs_forgot_password_success_content": "รหัสผ่านใหม่ได้รับการอัปเดตแล้ว",
  "bs_forgot_password_success_title": "Change password success",
  "bs_from": "From",
  "bs_get_left_behind": "get left behind",
  "bs_get_verification_code": "รับรหัสยืนยัน",
  "bs_get_verification_code_maximum": "Exceeded number of times",
  "bs_gmv": "GMV",
  "bs_gmv_actual": "GMV Actual",
  "bs_gmv_by_store": "GMV By Store",
  "bs_grow_revenue_or": "grow revenue or",
  "bs_harness_social_selling": "Harness social selling",
  "bs_head_quarter": "HeadQuarter",
  "bs_healthy": "Health",
  "bs_helper_attach_file": "The system will support performance statistics of the results of Tiktok channel",
  "bs_helper_download_template_import": "File supported: XLSX, CSV. Upto 500 lines",
  "bs_helper_download_template_import_max_4MB": "Supports xlsx format files, maximum 4MB",
  "bs_helper_download_template_import_max_num": "File supported: XLSX, CSV. Upto {{num}} lines",
  "bs_helper_drag_file": "No file chosen",
  "bs_helper_empty_data_search": "Please try again",
  "bs_helper_import_file_campaign": "Quickly create booking campaign information from available management files to manage on the system",
  "bs_helper_import_file_tiktok_counter": "The system only supports Tiktok video links",
  "bs_helper_linked_account": "Enter the Passio account name that the Host uses to monitor the live session",
  "bs_hide_chart": "Hide chart",
  "bs_home_page": "Home page",
  "bs_homepage": "Homepage",
  "bs_host_create_livestream_title_popup": "Create new live session",
  "bs_host_detail_livestream": "Detail live",
  "bs_host_name": "Host name",
  "bs_host_overview_report": "Overview Report",
  "bs_houseware": "Appliances",
  "bs_import": "Import",
  "bs_import_campaign": "Import Campaign",
  "bs_import_create_live": "Import Live session",
  "bs_import_error_file_excel_max": "Import không thành công, vui lòng chọn tệp có số hàng tối đa là 500",
  "bs_import_error_file_excel_total": "Your file has {{num}} invalid records",
  "bs_import_error_file_excel_type": "Import failed, please import file in xlsx and csv format",
  "bs_import_result": "Import result",
  "bs_import_result_live": "Import results",
  "bs_import_warning_file_excel_total": "In the file there are {{num}} records that are not your products. These records will not be displayed after import",
  "bs_inactive": "Inactive",
  "bs_indo": "Indonesia",
  "bs_indonesia": "Indonesia",
  "bs_indonesia_branch": "Indonesia Branch",
  "bs_info_campaign": "Campaign Info",
  "bs_insights_search_placeholder": "Search with Influencer name",
  "bs_instagram": "Instagram",
  "bs_invalid_field": "Data is invalid",
  "bs_item": "Item",
  "bs_join_date": "Join Date",
  "bs_key_date_vietnamese": "Thứ",
  "bs_key_sunday_vietnam": "Chủ nhật",
  "bs_keyword_requirement": "Keyword Requiment",
  "bs_keyword_unaccepted": "Keyword Unaccepted",
  "bs_kinds": "Booking product",
  "bs_kinds_is_required": "The Product Booking is required",
  "bs_koc_dashboard": "KOC Dashboard",
  "bs_koc_dashboard_description": "Overview of the quality of the KOCs joining your campaigns",
  "bs_koc_information": "KOC Information",
  "bs_koc_join": "KOC Join Campaigns",
  "bs_koc_joined_tooltip": "All the KOC sending the Promotion Kit Request ",
  "bs_koc_listing": "KOC Listing",
  "bs_koc_listing_title": "KOC Listing",
  "bs_koc_order": "KOC Have Orders",
  "bs_koc_profile": "KOC Profile",
  "bs_kol": "KOL",
  "bs_kol_range_age": "KOC Age",
  "bs_kol_range_age_is_required": "The KOC Age is required",
  "bs_kol_report_title": "KOL report",
  "bs_label_accept_the_post": "accept the post",
  "bs_label_agency_not_review": "Agency not rated yet",
  "bs_label_attach_file": "Import file",
  "bs_label_average_time_receive_video": "Average video reception time",
  "bs_label_brand_not_review": "Brand not rated yet",
  "bs_label_campaign": "Campaign",
  "bs_label_change_live_time": "Request to change live schedule",
  "bs_label_channel": "Channel",
  "bs_label_chart_trend_each_index": "Trend chart for each index",
  "bs_label_charts_activated_koc": "Activated KOC",
  "bs_label_charts_engagements": "Engagement",
  "bs_label_charts_posts": "Post",
  "bs_label_checkin_live": "Check-in livestream",
  "bs_label_choose_the_date": "Posted at",
  "bs_label_colum_AOV": "AOV",
  "bs_label_colum_KOLS": "KOL Number",
  "bs_label_colum_action": "Action",
  "bs_label_colum_actual_comission": "Actual commission",
  "bs_label_colum_actual_commissions_base": "Actual commissions base",
  "bs_label_colum_actual_revenue": "Actual revenue",
  "bs_label_colum_address": "Address",
  "bs_label_colum_agency": "Agency",
  "bs_label_colum_agency_review": "Agency rated",
  "bs_label_colum_amount": "Revenue",
  "bs_label_colum_area": "Area",
  "bs_label_colum_avg_view": "Average View",
  "bs_label_colum_avg_viewing_duration": "Average viewing time",
  "bs_label_colum_booking_cost": "Booking Cost",
  "bs_label_colum_brand": "Brand",
  "bs_label_colum_brand_name": "Brand Name",
  "bs_label_colum_brand_review": "Brand rated",
  "bs_label_colum_campaign_id": "Campaign id",
  "bs_label_colum_campaign_name": "Campaign name",
  "bs_label_colum_cancel_rate": "Cancellation rate",
  "bs_label_colum_cancel_rating": "Cancel rating",
  "bs_label_colum_comission": "Estimated commission",
  "bs_label_colum_comment": "Total comments",
  "bs_label_colum_commission_cost": "Commission Cost",
  "bs_label_colum_commissions": "Commissions",
  "bs_label_colum_cost_actual": "Actual cost",
  "bs_label_colum_cost_estimate": "Estimated cost",
  "bs_label_colum_country": "Country",
  "bs_label_colum_create_date": "Date created",
  "bs_label_colum_create_time": "Create time",
  "bs_label_colum_creator": "Creator",
  "bs_label_colum_creator_commissions": "Commission for creators",
  "bs_label_colum_creator_contact": "Creator Contact",
  "bs_label_colum_creator_info": "Creator Infomation",
  "bs_label_colum_creator_post": "Creator Posted",
  "bs_label_colum_creator_sale": "Creator Sales",
  "bs_label_colum_date": "Date",
  "bs_label_colum_drop_live": "Livestream dropout rate",
  "bs_label_colum_duration": "Duration",
  "bs_label_colum_duration_actual": "Actual duration",
  "bs_label_colum_duration_estimate": "Estimated duration",
  "bs_label_colum_effective_time": "Effective time",
  "bs_label_colum_estimate_commissions_base": "Estimate commissions base",
  "bs_label_colum_estimate_revenue": "Estimated revenue",
  "bs_label_colum_follower": "Followers",
  "bs_label_colum_gmv": "GMV (USD)",
  "bs_label_colum_gmv_cancel": "GMV cancel",
  "bs_label_colum_gmv_complete": "GMV completed",
  "bs_label_colum_gmv_pending": "GMV pending",
  "bs_label_colum_gmv_waiting": "GMV waiting",
  "bs_label_colum_id_live_stream": "ID livestream",
  "bs_label_colum_index": "No.",
  "bs_label_colum_influencer_name": "Influencer Name",
  "bs_label_colum_info_creator": "Creator Info",
  "bs_label_colum_item_cancel": "Item cancel",
  "bs_label_colum_item_complete": "Item completed",
  "bs_label_colum_item_pending": "Item pending",
  "bs_label_colum_kol": "KOL",
  "bs_label_colum_kol_host": "Host",
  "bs_label_colum_kol_name": "KOL",
  "bs_label_colum_kol_username": "Username",
  "bs_label_colum_like": "Total lives",
  "bs_label_colum_link_air": "Link Air",
  "bs_label_colum_link_profile": "Link Profile",
  "bs_label_colum_link_submit": "Submit Link",
  "bs_label_colum_live_actual": "Estimated live",
  "bs_label_colum_live_estimate": "Estimated live",
  "bs_label_colum_live_info": "Live Infomation",
  "bs_label_colum_live_session_quit_live": "Dropped Livestreams",
  "bs_label_colum_live_stream": "Livestream",
  "bs_label_colum_livestream": "Livestream sessions",
  "bs_label_colum_livestream_estimate": "Livestream estimate",
  "bs_label_colum_livestreams": "Livestreams",
  "bs_label_colum_name": "Name",
  "bs_label_colum_name_average_time_receive_video": "Average time receive video",
  "bs_label_colum_name_cost": "Cost (Actual/Estimate)",
  "bs_label_colum_name_job": "Name job",
  "bs_label_colum_name_kol_approved": "KOL approved",
  "bs_label_colum_name_kol_request": "KOL request",
  "bs_label_colum_name_live": "Live name",
  "bs_label_colum_name_live_room": "Live room name",
  "bs_label_colum_name_number_video_accepted": "Number video accepted",
  "bs_label_colum_name_number_video_order": "Number video order",
  "bs_label_colum_name_number_video_submit": "Number video submit",
  "bs_label_colum_name_status": "Status",
  "bs_label_colum_name_target": "Target",
  "bs_label_colum_name_type_video_order": "Type video order",
  "bs_label_colum_no": "No.",
  "bs_label_colum_order": "Total orders",
  "bs_label_colum_order_cancel": "Orders cancel",
  "bs_label_colum_order_complete": "Orders completed",
  "bs_label_colum_order_paid": "Order has been paid",
  "bs_label_colum_order_pending": "Order pending",
  "bs_label_colum_partner_commissions": "Commission for partners",
  "bs_label_colum_platform": "Platform",
  "bs_label_colum_post": "Post",
  "bs_label_colum_price": "Price",
  "bs_label_colum_price_and_quantity": "Price & quantity",
  "bs_label_colum_product": "Product",
  "bs_label_colum_product_click": "Clicks",
  "bs_label_colum_product_id": "Product ID",
  "bs_label_colum_product_impression": "Impressions",
  "bs_label_colum_product_info": "Product information",
  "bs_label_colum_product_name": "Product Name",
  "bs_label_colum_product_sample": "Sample products",
  "bs_label_colum_product_sold": "Product sold",
  "bs_label_colum_products": "Products (SKUs)",
  "bs_label_colum_quantity": "Quantity",
  "bs_label_colum_rating": "Rating",
  "bs_label_colum_relevant_influencer": "Relevant Influencer",
  "bs_label_colum_relevant_live": "Relevant Live",
  "bs_label_colum_relevant_videos": "Relevant Video",
  "bs_label_colum_rev": "Revenue",
  "bs_label_colum_rev_actual": "Actual revenue",
  "bs_label_colum_rev_estimate": "Estimated revenue",
  "bs_label_colum_revenue": "Total revenues",
  "bs_label_colum_revenue_live_hour": "Revenue/live hour",
  "bs_label_colum_revenue_live_session": "Revenue/Live Session",
  "bs_label_colum_revenue_orders": "Revenue",
  "bs_label_colum_revenue_video": "Revenue/Video",
  "bs_label_colum_sale": "Sale (Orders)",
  "bs_label_colum_sale_ammount": "Sale Amount",
  "bs_label_colum_sale_per_day": "Sales per day",
  "bs_label_colum_sales": "Sales",
  "bs_label_colum_sample_status": "Sample status",
  "bs_label_colum_settlement": "Order has been settled",
  "bs_label_colum_share": "Shares",
  "bs_label_colum_shop_code": "Shop code",
  "bs_label_colum_shop_name": "Shop name",
  "bs_label_colum_sku": "SKU",
  "bs_label_colum_sku_info": "SKU Info",
  "bs_label_colum_sold_count": "Item sold",
  "bs_label_colum_status": "Status",
  "bs_label_colum_status_description": "Turn ON to unlimit the number of live sessions using this live room at a time",
  "bs_label_colum_status_submit": "Submitted",
  "bs_label_colum_sub_brand": "Sub-brand",
  "bs_label_colum_suggested_creator": "Suggested Creator",
  "bs_label_colum_tiktok_username": "Tiktok username",
  "bs_label_colum_time": "Time",
  "bs_label_colum_time_approve": "Approved time",
  "bs_label_colum_time_reject": "Rejected time",
  "bs_label_colum_time_request": "Request time",
  "bs_label_colum_top_index": "Rank",
  "bs_label_colum_top_product": "Top 3 best-selling products",
  "bs_label_colum_total_buyer": "Total buyers",
  "bs_label_colum_total_gmv": "Total GMV",
  "bs_label_colum_total_item": "Total item",
  "bs_label_colum_total_order": "Orders",
  "bs_label_colum_total_posts": "Total Posts",
  "bs_label_colum_total_revenue": "Total Revenues",
  "bs_label_colum_total_sale": "Total sales",
  "bs_label_colum_total_social": "Total Social",
  "bs_label_colum_total_view": "Total views",
  "bs_label_colum_user_name": "Username",
  "bs_label_colum_video": "Videos",
  "bs_label_colum_video_count": "Total Booking",
  "bs_label_colum_view": "Views",
  "bs_label_comments_count": "Comments Count",
  "bs_label_congratulations_purchase_success": "Congratulations on your successful purchase",
  "bs_label_crawl_status": "Craw Data Status",
  "bs_label_created_type": "Type",
  "bs_label_diggs_count": "Diggs Count",
  "bs_label_import_file_campaign": "File Campaign",
  "bs_label_import_file_livestream_session": "Select File",
  "bs_label_import_file_sku_management": "Select File",
  "bs_label_import_file_tiktok_counter": "Results file",
  "bs_label_koc": "KOC",
  "bs_label_likes_count": "Likes Count",
  "bs_label_live": "Live sessions",
  "bs_label_name_number_follower": "Follower",
  "bs_label_number_video_accepted": "Number video tested",
  "bs_label_number_video_job_completed": "Number of video jobs completed",
  "bs_label_number_video_job_created": "Number of video jobs created",
  "bs_label_number_video_tested": "Number video tested",
  "bs_label_payment_failed": "Payment failed",
  "bs_label_payment_success": "Payment success",
  "bs_label_plays_count": "Plays Count",
  "bs_label_posted_date": "Posted at",
  "bs_label_reach_count": "Potential Reach",
  "bs_label_reactions_count": "Reactions Count",
  "bs_label_request_ads_code": "Request ads code",
  "bs_label_result_content": "Result Content",
  "bs_label_saves_count": "Saves Count",
  "bs_label_search_for_creator": "Search for creators",
  "bs_label_select_type_comments": "Most Comments",
  "bs_label_select_type_engagement": "Most Engagement",
  "bs_label_select_type_gmv": "Most GMV",
  "bs_label_select_type_likes": "Most Likes",
  "bs_label_select_type_recent": "Most Recent",
  "bs_label_select_type_shares": "Most Shares",
  "bs_label_select_type_views": "Most View",
  "bs_label_shares_count": "Shares Count",
  "bs_label_status": "Status",
  "bs_label_status_request_ads_code": "Requested",
  "bs_label_sub_brand": "Sub-brand",
  "bs_label_time_live": "Live time",
  "bs_label_total_cost": "Total cost",
  "bs_label_transaction_failed_title": "Transaction has not been successful",
  "bs_label_transaction_failed_try_again": "Please try other payment methods again",
  "bs_label_view_list_content": "View list content",
  "bs_label_views_count": "Views Count",
  "bs_label_violation": "Violating content",
  "bs_last_month": "Last month",
  "bs_later": "later",
  "bs_lemon8": "Lemon8",
  "bs_line": "Line",
  "bs_link_result": "Link result",
  "bs_list_campaign": "Campaign List",
  "bs_list_campaign_button_detail": "Detail",
  "bs_list_campaign_country": "Country",
  "bs_list_campaign_program_code": "Campaign ID",
  "bs_list_campaign_time_create": "Created at",
  "bs_list_campaign_time_to_happen": "Timeline",
  "bs_list_host_message_validate_mail": "Please enter a valid email",
  "bs_list_host_steps_connect_1": "Step 1: Host registers passio account",
  "bs_list_host_steps_connect_2": "Step 2: Login",
  "bs_list_host_steps_connect_3": "Step 3: Connect account",
  "bs_list_host_steps_connect_sub_1": "Host register passio account at the link below. If you already have an account, please skip this step.",
  "bs_list_host_steps_connect_sub_2": "Log in to the live management system at the link",
  "bs_list_host_steps_connect_sub_3": "Enter the code “<b>{{code}}</b>” into the link authentication popup to connect your account and view the live schedule list",
  "bs_list_host_title_delete_host": "Do you want to delete host \"{{name}}\"?",
  "bs_list_kol": "Propose KOLs",
  "bs_list_kol_placeholder_input": "Search for KOLs by name",
  "bs_list_kol_selected": "List of selected KOLs",
  "bs_list_kol_total_selected": "{{totalKol}} KOC selected",
  "bs_list_livestream": "Live management",
  "bs_list_order": "List Order",
  "bs_list_room_title_delete_room": "Do you want to delete room \"{{name}}\"?",
  "bs_list_sku_in_tap_action_more": "See Recommended Creators",
  "bs_list_sku_title_modal_edit_sku": "Sample product information",
  "bs_list_video_package_title": "Video Job",
  "bs_live_calendar_brand_logo_or_url_required": "Please upload logo or enter URL",
  "bs_live_calendar_change_live_time_success": "Successfully changed live schedule",
  "bs_live_calendar_checkin_success": "Check-in successfully",
  "bs_live_calendar_rating_1_star": "Bad",
  "bs_live_calendar_rating_2_star": "Unsatisfied",
  "bs_live_calendar_rating_3_star": "Normal",
  "bs_live_calendar_rating_4_star": "Satisfied",
  "bs_live_calendar_rating_5_star": "Great",
  "bs_live_calendar_rating_failed": "Rating failed",
  "bs_live_calendar_rating_note": "Note",
  "bs_live_calendar_rating_note_placeholder": "Enter note",
  "bs_live_calendar_rating_popup_title": "Your review",
  "bs_live_calendar_rating_success": "Rating success",
  "bs_live_calendar_rating_success_v2": "Evaluate the successful live session",
  "bs_live_campagin_kol_host_detail_title": "Detail host report",
  "bs_live_campagin_kol_host_report_title": "Host report",
  "bs_live_campagin_kol_manage_room_live_title": "Manage live room",
  "bs_live_performance_label_campaign": "Campaign",
  "bs_live_performance_label_hour": "hours",
  "bs_live_performance_label_reach_performance": "Engagement Performance",
  "bs_live_performance_label_sale_performance": "Sales Performance",
  "bs_live_performance_label_session": "session(s)",
  "bs_live_performance_label_top_agency": "Top Agency",
  "bs_live_performance_label_top_kol": "Top Host",
  "bs_live_performance_label_top_livestream": "Top Livestream",
  "bs_live_performence_c_o": "Average C_O",
  "bs_live_performence_campaign": "Campaign",
  "bs_live_performence_ctr": "Average CTR",
  "bs_live_performence_kol_host": "Host",
  "bs_live_performence_report_menu": "Live performance",
  "bs_live_performence_target_revenue": "Target revenue",
  "bs_live_performence_title_page": "Livestream Performance",
  "bs_live_performence_total_buyer": "Total buyers",
  "bs_live_performence_total_click": "Clicks product",
  "bs_live_performence_total_comment": "Total comments",
  "bs_live_performence_total_cost": "Cost",
  "bs_live_performence_total_like": "Total likes",
  "bs_live_performence_total_live": "Live sessions",
  "bs_live_performence_total_order": "Total orders",
  "bs_live_performence_total_product": "Total products",
  "bs_live_performence_total_revenue": "Total revenues",
  "bs_live_performence_total_time": "Total time",
  "bs_live_performence_total_view": "Total views",
  "bs_live_performence_total_viewer": "Total viewers",
  "bs_live_revenue_label": "Live session revenue",
  "bs_live_stream": "Livestream",
  "bs_livestream": "Livestream",
  "bs_livestream_another_lives_session": "Another live session",
  "bs_livestream_calendar": "Livestream calendar",
  "bs_livestream_calendar_add_info": "Additional information",
  "bs_livestream_calendar_agency": "Agency",
  "bs_livestream_calendar_agency_rating": "Agency rated",
  "bs_livestream_calendar_agree_and_edit": "Agree and edit",
  "bs_livestream_calendar_agree_label": "Confirm",
  "bs_livestream_calendar_appearance": "Appearance",
  "bs_livestream_calendar_approve_by": "Approve by",
  "bs_livestream_calendar_approved": "Approved",
  "bs_livestream_calendar_avg_score": "Average score",
  "bs_livestream_calendar_being_rejected": "Rejected",
  "bs_livestream_calendar_brand": "Brand",
  "bs_livestream_calendar_brand_no_rating": "You have not rated yet",
  "bs_livestream_calendar_brand_rating": "Brand rated",
  "bs_livestream_calendar_campaign": "Campaign",
  "bs_livestream_calendar_cancel_label": "Cancel",
  "bs_livestream_calendar_cancel_live": "Request to cancel live schedule",
  "bs_livestream_calendar_cancelled": "Cancelled",
  "bs_livestream_calendar_change_live_time": "Change live schedule",
  "bs_livestream_calendar_change_live_time_warning": "Cannot change or cancel live schedule",
  "bs_livestream_calendar_change_live_time_warning_content": "Additional requests cannot be created while there are pending requests",
  "bs_livestream_calendar_change_live_time_warning_content_about_time": "The live session will start in the next {{time}} hours, you cannot request to change the live schedule",
  "bs_livestream_calendar_change_live_time_warning_title": "Cannot request to change live schedule",
  "bs_livestream_calendar_change_time_live": "Request to change information",
  "bs_livestream_calendar_check_in_now": "Check-in now",
  "bs_livestream_calendar_checked_in": "Checked-in",
  "bs_livestream_calendar_close": "Close",
  "bs_livestream_calendar_effectiveness": "Livestream performance",
  "bs_livestream_calendar_expertise": "Industry knowledge",
  "bs_livestream_calendar_forget_check_in": "Forgot check-in",
  "bs_livestream_calendar_full_time": "Full time",
  "bs_livestream_calendar_fully_prepared": "Prepare full costume",
  "bs_livestream_calendar_img_checkin": "Check-in image",
  "bs_livestream_calendar_improve_gmv": "Improve GMV",
  "bs_livestream_calendar_live": "Happenning",
  "bs_livestream_calendar_live_room": "Live room",
  "bs_livestream_calendar_live_session_chart": "Live streaming frequency chart",
  "bs_livestream_calendar_live_time": "Live time",
  "bs_livestream_calendar_livestream_style": "Livestream manners",
  "bs_livestream_calendar_load_more_img": "Upload more image",
  "bs_livestream_calendar_makeup": "Makeup",
  "bs_livestream_calendar_new_time": "New live time",
  "bs_livestream_calendar_no_plan": "There are no live sessions",
  "bs_livestream_calendar_no_rating": "Agency has not rated yet",
  "bs_livestream_calendar_not_rating": "Not yet rated",
  "bs_livestream_calendar_not_yet": "Haven't happened yet",
  "bs_livestream_calendar_note": "Note",
  "bs_livestream_calendar_own_work": "Not private work",
  "bs_livestream_calendar_placeholder_reject_reason": "Enter the reason for rejection",
  "bs_livestream_calendar_policy": "Update sales policy",
  "bs_livestream_calendar_preserve": "Preserve device & clean",
  "bs_livestream_calendar_quit_live": "Dropped out",
  "bs_livestream_calendar_rated": "Rated",
  "bs_livestream_calendar_rating_now": "Rating now",
  "bs_livestream_calendar_reason_reject": "Reason reject",
  "bs_livestream_calendar_reject": "Reject",
  "bs_livestream_calendar_reject_by": "Reject by",
  "bs_livestream_calendar_reject_reason": "Reject reason",
  "bs_livestream_calendar_request_cancle_live_name": "Host \"{{name}}\" request to change live time",
  "bs_livestream_calendar_request_change_time_name": "Host \"{{name}}\" request to change information",
  "bs_livestream_calendar_skill": "Livestream skill",
  "bs_livestream_calendar_style": "Livestream style",
  "bs_livestream_calendar_sub_brand": "Sub-brand",
  "bs_livestream_calendar_suit": "Clothes",
  "bs_livestream_calendar_sure_agree": "You want to approve the Host's request?",
  "bs_livestream_calendar_sure_reject": "Are you sure you want to decline the Host's request?",
  "bs_livestream_calendar_target_gmv": "Target GMV",
  "bs_livestream_calendar_time": "On time",
  "bs_livestream_calendar_time_approve": "Time approve",
  "bs_livestream_calendar_time_checkin": "Check-in time",
  "bs_livestream_calendar_time_reject": "Time reject",
  "bs_livestream_calendar_time_request": "Time request",
  "bs_livestream_calendar_title_card_detail": "Detail infomation",
  "bs_livestream_calendar_top_live": "Top livestream",
  "bs_livestream_calendar_top_revenue": "Supper sellers",
  "bs_livestream_calendar_view_detail": "View detail",
  "bs_livestream_calendar_voice": "Voice",
  "bs_livestream_calendar_waiting_approve": "Waiting approve",
  "bs_livestream_calendar_your_rating": "Your rating",
  "bs_livestream_change_request_failed": "Cancel request failed",
  "bs_livestream_change_request_required_failed": "Please enter a reason for the rejection",
  "bs_livestream_change_request_success": "Cancel request successfully",
  "bs_livestream_column_data_import": "Upload results",
  "bs_livestream_column_data_suggest": "Live session is suggested",
  "bs_livestream_column_data_system": "Live session on the system",
  "bs_livestream_cost_estimate": "Booking Fee",
  "bs_livestream_empty_live_suggest": "No live sessions suggested",
  "bs_livestream_error_date": "Please select a start date that is smaller than the end date",
  "bs_livestream_error_host_checked": "Host checked-in! Live sessions cannot be cancelled",
  "bs_livestream_error_host_not_ready": "Host already had another live session at this time",
  "bs_livestream_error_live_cancel": "The live version has been cancelled! Please check again",
  "bs_livestream_error_live_room_used": "The live room was in use at this live time",
  "bs_livestream_label_agency": "Agency",
  "bs_livestream_label_campaign": "Campaign",
  "bs_livestream_label_checkin_img_url": "Or enter url image",
  "bs_livestream_label_content": "Content",
  "bs_livestream_label_cost_estimate": "Cost estimate",
  "bs_livestream_label_duration": "Live duration",
  "bs_livestream_label_filter_campaign": "Campaign",
  "bs_livestream_label_filter_date": "Live stream time",
  "bs_livestream_label_filter_kol_host": "Host",
  "bs_livestream_label_filter_platform": "Platform",
  "bs_livestream_label_gmv": "Target GMV",
  "bs_livestream_label_host": "Host",
  "bs_livestream_label_live_id": "Livestream ID",
  "bs_livestream_label_live_room": "Live room",
  "bs_livestream_label_name": "Livestream name",
  "bs_livestream_label_note": "Note",
  "bs_livestream_label_platform": "Platform",
  "bs_livestream_label_sub_brand": "Sub-brand",
  "bs_livestream_label_time_live": "Live stream time",
  "bs_livestream_label_url_format": "Please input the correct URL",
  "bs_livestream_label_url_placeholder": "Input url image",
  "bs_livestream_message_create_success": "Tạo mới phiên live thành công",
  "bs_livestream_message_duplicate_room_used": "The live room was in use at this live time",
  "bs_livestream_message_edit_success": "Chinh sửa phiên live thành công",
  "bs_livestream_message_host_already_booked": "Host already booked for this time slot",
  "bs_livestream_message_host_had_another_live": "Host already had another live session at this time",
  "bs_livestream_message_room_already_used": "Room already used for another live session",
  "bs_livestream_not_checkin_yet": "Not checked in yet",
  "bs_livestream_performance_agency_title": "Agency",
  "bs_livestream_placeholder_sort_by": "Sort by",
  "bs_livestream_report_title": "Livestream report",
  "bs_livestream_text_PIC": "PIC",
  "bs_livestream_text_action": "Action",
  "bs_livestream_text_agv_viewers": "Avg. viewers",
  "bs_livestream_text_average_price": "Average price",
  "bs_livestream_text_avg_viewing_duration": "Avg. Viewing Duration",
  "bs_livestream_text_buyers": "Buyers",
  "bs_livestream_text_campaign": "Campaign",
  "bs_livestream_text_check_info": "Check-in Info",
  "bs_livestream_text_co": "C_O",
  "bs_livestream_text_comments": "Comments",
  "bs_livestream_text_ctr": "CTR",
  "bs_livestream_text_duration": "Duration",
  "bs_livestream_text_end_time": "End Time",
  "bs_livestream_text_engagement_performance": "Engagement performance",
  "bs_livestream_text_engagement_rate": "Engagement rate",
  "bs_livestream_text_gmv": "Target GMV",
  "bs_livestream_text_gross_revenue": "Gross revenue",
  "bs_livestream_text_items_sold": "Items sold",
  "bs_livestream_text_kol_host": "Host",
  "bs_livestream_text_likes": "Likes",
  "bs_livestream_text_live_info": "Live Info",
  "bs_livestream_text_live_time": "Live stream time",
  "bs_livestream_text_livestream": "Livestream",
  "bs_livestream_text_livestream_id": "Livestream_id",
  "bs_livestream_text_minutes": "minutes",
  "bs_livestream_text_new_follower": "New followers",
  "bs_livestream_text_new_followers": "New followers",
  "bs_livestream_text_note": "Note",
  "bs_livestream_text_orders": "Orders",
  "bs_livestream_text_orders_created": "Orders created",
  "bs_livestream_text_orders_paid_for": "Orders paid for",
  "bs_livestream_text_other_metrics": "Other metrics",
  "bs_livestream_text_pcu": "PCU",
  "bs_livestream_text_platform": "Platform",
  "bs_livestream_text_product_clicks": "Product clicks",
  "bs_livestream_text_product_impressions": "Product impressions",
  "bs_livestream_text_reason": "Reason",
  "bs_livestream_text_sale_performance": "Sale performance",
  "bs_livestream_text_show_gpm": "Show GPM",
  "bs_livestream_text_start_time": "Start time",
  "bs_livestream_text_viewers": "Viewers",
  "bs_livestream_text_views": "Views",
  "bs_livestream_text_watch_gpm": "Watch GPM",
  "bs_livestream_title_modal_cancel_session": "Are you sure you want to cancel this live session?",
  "bs_livestream_title_table_list": "List",
  "bs_livestream_tooltip_watch_gpm": "Revenue per 1000 watchs",
  "bs_living_area": "Area",
  "bs_log_out": "Log Out",
  "bs_login": "เข้าสู่ระบบ",
  "bs_login_account_erorr_verify": "Account is not active",
  "bs_login_account_err_locked": "Your account has been locked",
  "bs_login_failed_title": "Login Failed",
  "bs_login_follow_processing": "Tracking progress with high speed and convenience",
  "bs_login_information_incorrect": "Login information is not correct. Please try again!",
  "bs_login_successfully_title": "You have successfully logged in!",
  "bs_macro": "Macro: from 100.000 to 1.000.000 followers",
  "bs_macro_not_des": "Macro",
  "bs_malaysia": "Malaysia",
  "bs_male": "Male",
  "bs_manage_connect_connect_history": "Connection history",
  "bs_manage_connect_content_popup_connect": "Are you sure you want to reconnect with the store? Data during the connection shutdown period will not be updated again",
  "bs_manage_connect_content_popup_disconnect": "Are you sure you want to disconnect the store. Once disconnected, the store's data will not be updated.",
  "bs_manage_connect_title_card": "List of stores",
  "bs_manage_list_host_title_page": "Manage list host",
  "bs_manage_video_list_title": "List of requests",
  "bs_manage_video_title": "List video job",
  "bs_manual_connect_host_description_1": "Manage, operate and evaluate live sessions easier and faster by guiding Hosts to use the Passio system.",
  "bs_manual_connect_host_description_2": "Hosts can track live schedules, check-in to start sessions, update revenue, submit schedule change requests, and monitor their performance reports.",
  "bs_manual_connect_host_title": "Passio Link Host Guide",
  "bs_max_file_size": "Maximum file size: 4mb",
  "bs_media_type": "Booking product",
  "bs_menu_affiliate_marketing_campaign": "Shopee - Lazada Affiliate",
  "bs_menu_booking_campaign": "UGC KOL",
  "bs_menu_booking_management": "List UGC Campaign",
  "bs_menu_booking_video_performent": "Booking Video Performent",
  "bs_menu_count_tiktok": "Tiktok Video Counter",
  "bs_menu_dashboard": "Dashboard",
  "bs_menu_detail_koc_report": "Report by KOC",
  "bs_menu_detailed_report": "Report by Campaign",
  "bs_menu_effective_approach": "UGC Reach Report",
  "bs_menu_explore_tiktok_channel": "Tiktok Channel Discovery",
  "bs_menu_explore_tiktok_shop": "Tiktok Store Discovery",
  "bs_menu_general_report": "General Report",
  "bs_menu_group_booking_video": "Booking Video",
  "bs_menu_group_creator": "Creator",
  "bs_menu_group_kol_live": "KOL Live",
  "bs_menu_group_live": "Brand Live",
  "bs_menu_group_order": "Order",
  "bs_menu_group_product": "Product",
  "bs_menu_group_video": "TAP Video",
  "bs_menu_host_livestream_calendar": "Livestream calendar",
  "bs_menu_host_overview_report": "Overview report",
  "bs_menu_lib_tools": "Other tool",
  "bs_menu_list_booking_video": "List Booking Video",
  "bs_menu_list_non_booking_video": "List TAP video",
  "bs_menu_list_video_job": "List Video Job",
  "bs_menu_list_video_package": "Purchase Video Package",
  "bs_menu_live_campaign": "Brand live",
  "bs_menu_manage_list_kol": "KOL Management",
  "bs_menu_product_management": "SKU Management",
  "bs_menu_report": "Report",
  "bs_menu_result_campaign": "UGC Results",
  "bs_menu_sale_performance": "List Orders",
  "bs_menu_sales_report": "Order dashboard",
  "bs_menu_setting_shop": "Shop setup",
  "bs_menu_store_report": "Report by Shop",
  "bs_menu_system_management": "Account",
  "bs_menu_tiktok_affiliate": "TIKTOK AFFILIATE",
  "bs_menu_video_jobs": "UGC Brand",
  "bs_message_added": "Linked success",
  "bs_message_approved": "Approved",
  "bs_message_cancel_done": "Cancellation successful",
  "bs_message_create_success": "Create Success",
  "bs_message_delete_campaign_success": "Deleted campagin successfully",
  "bs_message_delete_host_error": "Host does not exist",
  "bs_message_delete_host_success": "Deleted successfully",
  "bs_message_delete_success": "Deleted successfully",
  "bs_message_edit_success": "Updated successfully",
  "bs_message_import_success": "Imported successfully",
  "bs_message_rejected": "Rejected request to receive sample products",
  "bs_message_success_crawl_campaign": "Crawled Successfully",
  "bs_message_update_host_success": "Update successful",
  "bs_message_update_product_fail": "Updating product information failed. Please try again!",
  "bs_message_update_product_success": "Updated product information successfully",
  "bs_micro": "Micro: from 10.000 to 100.000 followers",
  "bs_micro_not_des": "Micro",
  "bs_million": "Million",
  "bs_minutes": "minutes",
  "bs_mom_and_baby": "Mom&Baby",
  "bs_month": "Month",
  "bs_monthly_marketing_budget": "งบประมาณการตลาดรายเดือน",
  "bs_monthly_marketing_budget_placeholder": "ระบุงบประมาณ",
  "bs_monthly_revenue": "รายได้รายเดือน",
  "bs_monthly_revenue_placeholder": "ระบุรายได้",
  "bs_nano": "Nano: less than 10.000 followers",
  "bs_nano_not_des": "Nano",
  "bs_new_koc_joined": "New KOC Join Campaigns",
  "bs_next": "Next",
  "bs_nmv": "NMV",
  "bs_no_koc_in_campaigns": "There are currently no KOCs joining your campaigns",
  "bs_no_requirement": "No Requirement",
  "bs_no_v2": "No",
  "bs_not_a_member_yet": "Not a member yet?",
  "bs_not_already_have_account": "คุณยังไม่มีบัญชี",
  "bs_not_requested": "Not request",
  "bs_not_violate": "Not Violated",
  "bs_notification_success_create_campaign": "Select KOC successfully",
  "bs_occur_time": "Timeline",
  "bs_ok_text_modal": "OK",
  "bs_on": "On",
  "bs_operation_tool": "Operation tool",
  "bs_optimize_performance": "Optimize time and efficiency of reporting campaigns",
  "bs_option_all": "All",
  "bs_option_all_type": "All type",
  "bs_option_crawled": "Crawled",
  "bs_option_dont_have_sample_product": "There are no sample products available",
  "bs_option_have_sample_product": "Sample products are available",
  "bs_option_livestream": "Livestream",
  "bs_option_not_crawl": "Haven't been crawled",
  "bs_option_select_empty_data_crawl": "No data",
  "bs_option_select_not_empty_data_crawl": "Data is available",
  "bs_option_showcase": "Showcase",
  "bs_option_sort_by_collect_dec": "The number of bookmarks saved gradually decreases",
  "bs_option_sort_by_comment_dec": "Number of Comments gradually decreases",
  "bs_option_sort_by_default": "Default",
  "bs_option_sort_by_like_dec": "The number of likes gradually decreases",
  "bs_option_sort_by_share_dec": "The number of shares is gradually decreasing",
  "bs_option_sort_by_view_dec": "Views are decreasing",
  "bs_option_video": "Video",
  "bs_options": "Option",
  "bs_order": "Order",
  "bs_order_id": "Order ID",
  "bs_order_report_text_campaign": "Campaign",
  "bs_order_report_text_creator": "Creator",
  "bs_order_report_text_date": "Date",
  "bs_order_report_text_platform": "Platform",
  "bs_order_report_text_product": "Product",
  "bs_order_report_text_shop": "Shop",
  "bs_order_report_text_type": "Type",
  "bs_order_status_search_kol": "KOL",
  "bs_order_status_search_product": "Product",
  "bs_order_status_search_title": "Order status",
  "bs_orders": "Orders",
  "bs_orders_report_title_table": "List Reports",
  "bs_orders_summary": "Orders Summary",
  "bs_other": "Other",
  "bs_our_pricing": "Our Pricing",
  "bs_overview_live_forget_check_in": "Forgot to check-in",
  "bs_overview_live_forget_check_in_rate": "Late check-in rate",
  "bs_overview_live_session_today": "Live session today",
  "bs_overview_live_session_upcoming": "Live session coming soon",
  "bs_overview_live_session_upcoming_tooltip": "Number of live sessions that will start within the next 12 hours",
  "bs_overview_live_session_upcoming_tooltip_v2": "Number of live sessions that will start within the next 12 hours",
  "bs_overview_live_total_live_hour": "Total hours lived",
  "bs_overview_live_total_live_hour_tooltip": "Total duration of live sessions that have ended and been checked in",
  "bs_overview_live_total_live_session_tooltip": "Including live sessions that have not yet taken place",
  "bs_overview_live_total_order": "Total orders",
  "bs_overview_live_total_time": "Total time",
  "bs_overview_live_total_time_tooltip": "Total duration of all live sessions",
  "bs_overview_live_total_view": "Total views",
  "bs_overview_text_categories": "Categories:",
  "bs_overview_text_country": "Country:",
  "bs_overview_you_rate": "You rated",
  "bs_partners": "Partners",
  "bs_passio_guidebook": "Passio Guidebook",
  "bs_password": "Password",
  "bs_password_is_required": "Password is required",
  "bs_performace_report": "Performace Report",
  "bs_performance": "Performance",
  "bs_performance_report": "Performance Report",
  "bs_philippine_branch": "Philippine Branch",
  "bs_philippines": "Philippines",
  "bs_photo": "Photo",
  "bs_placeholder_address": "Enter address",
  "bs_placeholder_area": "Ex: Ho Chi Minh, Ha noi",
  "bs_placeholder_brand": "Your Brand",
  "bs_placeholder_create_category": "Enter a category name",
  "bs_placeholder_create_sub_brand": "Enter a sub-brand",
  "bs_placeholder_email": "Your Email",
  "bs_placeholder_host_email": "Enter email",
  "bs_placeholder_host_name": "Enter host name",
  "bs_placeholder_input_cfm_password": "ยืนยันรหัสผ่าน",
  "bs_placeholder_input_code_host": "Enter the connect code",
  "bs_placeholder_input_date_rage": "Choose Date Range",
  "bs_placeholder_input_email": "อีเมล",
  "bs_placeholder_input_keyword": "Input Keyword",
  "bs_placeholder_input_password": "รหัสผ่าน",
  "bs_placeholder_input_verifi_code": "รหัสยืนยัน",
  "bs_placeholder_leave_mess": "Enter the reason for wanting to match the schedule",
  "bs_placeholder_mess": "Leave us a message",
  "bs_placeholder_name": "Your Name",
  "bs_placeholder_name_live_room": "Enter live room name",
  "bs_placeholder_new_input_password": "รหัสผ่านใหม่",
  "bs_placeholder_phone_number": "Your Phone number",
  "bs_placeholder_quantity": "Enter quantity",
  "bs_placeholder_search_by_name_room": "Search by room name",
  "bs_placeholder_select_date": "Age",
  "bs_placeholder_store": "Your Website or Online store",
  "bs_placeholder_username_passio": "Enter username",
  "bs_platform_search_title": "Platform",
  "bs_please_enter_item": "Please Enter Keyword",
  "bs_please_select": "Please select",
  "bs_please_select_shop": "Select Store",
  "bs_popover_confirm_remove_sample_product": "You definitely want to get rid of product samples",
  "bs_popup_confirm_content_request_ads_code": "Do you want to see the ad code for this video? Submit a request and we'll get back to you as soon as possible.",
  "bs_popup_confirm_request_ads_code": "Request to see ads code",
  "bs_preparing": "Preparing",
  "bs_price": "Estimate Price",
  "bs_privacy_policy": "Privacy Policy",
  "bs_product": "Product",
  "bs_product_report_title": "SKU report",
  "bs_product_search_title": "Product",
  "bs_promotion_approved": "Promotion Approved",
  "bs_promotion_item": "Promotion item",
  "bs_promotion_requests": "Promotion Requests",
  "bs_range_age_from": "Age from:",
  "bs_range_picker_placeholder_form_date": "From",
  "bs_range_picker_placeholder_to_date": "to",
  "bs_reason": "Reason",
  "bs_register": "ลงทะเบียน",
  "bs_register_live_calendar": "Register live schedule",
  "bs_register_live_schedule": "Live schedule",
  "bs_reject_by_seller": "Rejected",
  "bs_remember_login": "Remember Login",
  "bs_report_last_7_days": "Report Last 7 Days",
  "bs_report_result": "KOL Report",
  "bs_report_result_placeholder_input_search": "Search by name, email...",
  "bs_report_result_placeholder_select_channels": "Select chanel",
  "bs_report_result_placeholder_select_type_followers": "Select KOL type",
  "bs_report_this_month": "Report This Month",
  "bs_request": "Request",
  "bs_request_booking": "Booking require",
  "bs_resend_verification_code": "ส่งอีกครั้งใน",
  "bs_reset_search_button": "Reset",
  "bs_result_campaign_placeholder_input_search": "Search by keyword…",
  "bs_result_koc_name": "KOC name",
  "bs_result_link": "Result link",
  "bs_result_list": "Result List",
  "bs_result_placeholder_choose_the_date": "Choose the date",
  "bs_result_placeholder_enter_number": "Enter the number",
  "bs_result_placeholder_import_search_campaign": "Choose the campaign",
  "bs_result_placeholder_input_search_KOC": "Search KOC",
  "bs_result_placeholder_input_search_campaign": "Search campaign",
  "bs_result_placeholder_input_search_text": "Search result",
  "bs_result_placeholder_result_content": "Enter the content",
  "bs_result_placeholder_select_filter_empty_data": "Crawl data status",
  "bs_result_placeholder_select_filter_violable": "Violating content",
  "bs_result_placeholder_select_search_KOC": "All KOC",
  "bs_result_placeholder_select_search_campaign": "All campaign",
  "bs_result_placeholder_select_search_channel": "All channel",
  "bs_result_title_modal_edit": "Edit result",
  "bs_results_list": "Results List",
  "bs_return_home": "Go to home",
  "bs_revenue_button_content": "Explore more",
  "bs_revenue_content": "The Southeast Asia's most positive platform for Brand to engage Creator. Harnessing social selling, winning clients and growing revenue.",
  "bs_rows_per_page": "records per page",
  "bs_rows_showing": "Showing {{fromNumber}} to {{toNumber}} of {{totalNumber}} records",
  "bs_sale_amount": "Sale amount",
  "bs_sale_performance": "List Orders",
  "bs_sale_performance_commission_title": "Commission",
  "bs_sale_performance_conversion_time_title": "Conversion Time",
  "bs_sale_performance_fulfilled_time_title": "Fulfilled Time",
  "bs_sale_performance_order_amount_title": "Order Amount",
  "bs_sale_performance_order_id_title": "Order ID",
  "bs_sale_performance_order_status_title": "Order status",
  "bs_sale_performance_placeholder_search": "ค้นหาตามชื่อผู้ขาย",
  "bs_sale_performance_platform_title": "Platform",
  "bs_sale_performance_quantity_title": "Quantity",
  "bs_sale_performance_seller_id_title": "Seller ID",
  "bs_sale_performance_seller_name_title": "Seller Name",
  "bs_sale_performance_sku_id_title": "SKU ID",
  "bs_sale_performance_sku_name_title": "SKU Name",
  "bs_sale_performance_traffic_source_title": "Traffic source",
  "bs_sale_performance_valid_status_title": "Valid status",
  "bs_save": "Save",
  "bs_save_list_kol": "Confirm",
  "bs_search": "Search",
  "bs_search_button": "Search",
  "bs_search_by_id_or_name_placeholder": "Search by livestream ID, Username",
  "bs_search_by_id_or_name_video_placeholder": "Search by video ID, tiktok username",
  "bs_search_by_name_id_placeholder": "Search by id, name",
  "bs_search_by_name_placeholder": "Search by name",
  "bs_search_campaign_bookings": "Search",
  "bs_search_koc": "Search KOC",
  "bs_seen": "Seen",
  "bs_select_age_value": "Age {{ageForm}} - {{ageTo}}",
  "bs_select_all_status": "All status",
  "bs_select_canceled_status": "Canceled",
  "bs_select_cancelled": "Cancelled",
  "bs_select_completed": "Completed",
  "bs_select_completed_status": "Completed",
  "bs_select_option_platform_placeholder": "Choose platform",
  "bs_select_pending": "Pending",
  "bs_select_pending_status": "Pending",
  "bs_select_platform_facebook": "Facebook",
  "bs_select_platform_instagram": "Instagram",
  "bs_select_platform_lazada": "Lazada",
  "bs_select_platform_other": "Other",
  "bs_select_platform_shopee": "Shopee",
  "bs_select_platform_tiki": "Tiki",
  "bs_select_platform_tiktok": "Tiktok",
  "bs_select_platform_website": "Website",
  "bs_select_status_campaign_placeholder": "Select status",
  "bs_send": "SEND",
  "bs_send_mail_campaign_helper_title": " Email your chosen Creators to introduce this campaign",
  "bs_send_mail_campaign_input_brand_description": "Brand and Product Description",
  "bs_send_mail_campaign_input_placeholder_brand_description": "Enter the description",
  "bs_send_mail_campaign_input_placeholder_request_content": "Enter the description",
  "bs_send_mail_campaign_input_request_content": "Require Description",
  "bs_send_mail_campaign_title": "Send Email",
  "bs_send_verification_code_success": "รหัสยืนยันได้ถูกส่งไปยังอีเมลของคุณแล้ว",
  "bs_sex": "Gender",
  "bs_sex_is_required": "The Gender field is required",
  "bs_shipping": "Shipping",
  "bs_shop_name": "Shop name",
  "bs_short_video": "Short video",
  "bs_showcase": "Showcase",
  "bs_sign_in": "Sign In",
  "bs_sign_in_agency": "Login agency",
  "bs_sign_in_host": "Login host",
  "bs_sign_up": "Sign Up",
  "bs_sign_up_failed_title": "ลงทะเบียนล้มเหลว",
  "bs_sign_up_information_incorrect": "ข้อมูลการลงทะเบียนไม่ถูกต้อง",
  "bs_sign_up_input_verify_code_required": "Please input verification code",
  "bs_sign_up_success_title": "บัญชีที่ลงทะเบียนเรียบร้อยแล้ว",
  "bs_sign_up_text": "ลงทะเบียน",
  "bs_singapore": "Singapore",
  "bs_singapore_branch": "Singapore Branch",
  "bs_sku": "SKU",
  "bs_sku_is_required": "You have not selected any SKU yet",
  "bs_sku_management": "SKU Management",
  "bs_sku_management_placeholder_search": "Search by code, name",
  "bs_sku_management_placeholder_select_sub_brand": "Select Sub-brand",
  "bs_sku_management_sku_categories": "Categories",
  "bs_sku_management_sku_id": "ID",
  "bs_sku_management_sku_name": "Product Name",
  "bs_sku_management_text_loading_import": "Importing, please wait!",
  "bs_sku_report_detail_content": "Detail Post",
  "bs_social_media": "Social media",
  "bs_social_media_is_required": "The Social media field is required",
  "bs_social_network_channel": "Social Network Channel",
  "bs_social_performance": "Social Performance",
  "bs_social_report_title_most_activated": "Most Activated KOC",
  "bs_social_report_title_most_engagement": "Most Engagement KOC",
  "bs_social_report_title_most_post_comment": "Most post comment",
  "bs_social_report_title_most_video_view": "Most Video View",
  "bs_some_thing_wrong": "Oops! Some thing went wrong.",
  "bs_sort_by_agency_high_rating": "Agency highly rating",
  "bs_sort_by_agency_rating": "Agency rated",
  "bs_sort_by_brand_high_rating": "Brand highly rating",
  "bs_sort_by_brand_rating": "Brand rated",
  "bs_sort_by_duration": "Livestreamed duration",
  "bs_sort_by_live": "Livestreams completed",
  "bs_sort_by_order": "Total orders",
  "bs_sort_by_order_average": "Orders / Live",
  "bs_sort_by_order_count": "Highest order",
  "bs_sort_by_paid_amount": "Highest revenue",
  "bs_sort_by_revenue": "Total revenues",
  "bs_sort_by_revenue_average": "Revenue / Live session",
  "bs_sort_by_revenue_per_hour": "Revenue / live hours",
  "bs_sort_by_room_count": "Most livestreams",
  "bs_sort_by_total_post": "Most posted",
  "bs_sort_by_video_count": "Most videos",
  "bs_sort_by_view_count": "Highest views",
  "bs_sport": "Sport",
  "bs_stat_title_comment": "Comments",
  "bs_stat_title_diggs": "Diggs",
  "bs_stat_title_like": "Likes",
  "bs_stat_title_play": "Plays",
  "bs_stat_title_reach": "Potential Reach",
  "bs_stat_title_reaction": "Reactions",
  "bs_stat_title_save": "Saves",
  "bs_stat_title_share": "Shares",
  "bs_stat_title_views": "Views",
  "bs_status": "Status",
  "bs_status_campaign": "Status Campaign",
  "bs_store": "Store",
  "bs_store_name": "Store Name",
  "bs_store_reports_title": "Store reports",
  "bs_sub_brand": "Sub-brand",
  "bs_sub_brand_is_required": "You have not selected any sub-brand yet",
  "bs_sub_brand_report_title": "Sub-brand Report",
  "bs_sub_brands": "Sub-brand",
  "bs_sub_title_modal_map_data_live": "The uploaded file contains results that have not been assigned to a live session on the system. Please select the live session that corresponds to your results.",
  "bs_submit_campagin_error_file_length": "Maximum file size: 30MB",
  "bs_submit_campagin_error_file_type": "Support formats: jpg, jpeg, gif, png, bmp, webp, HEIC",
  "bs_submit_campagin_send_campagin_result": "Send acceptance results",
  "bs_submit_campagin_submit_error": "Submit result failed",
  "bs_submit_campagin_text_button_upload": "Upload",
  "bs_submit_campaign_button_resend_submit": "Resend result",
  "bs_submit_campaign_button_send_result": "Submit results",
  "bs_submit_campaign_category_label": "Category",
  "bs_submit_campaign_help_text_no_image": "Click the “Upload” button to add your results",
  "bs_submit_campaign_input_attachment": "Attachments",
  "bs_submit_campaign_input_attachment_description": "Maximum file size: 30MB. Support formats: jpg, jpeg, gif, png, bmp, webp, HEIC",
  "bs_submit_campaign_input_channel_required": "Please enter the channel link",
  "bs_submit_campaign_input_comment_count": "Comment",
  "bs_submit_campaign_input_link_placeholder": "Enter the link",
  "bs_submit_campaign_input_link_required": "Please enter the submit link",
  "bs_submit_campaign_input_link_submit": "Submit link",
  "bs_submit_campaign_input_number_placeholder": "Enter number",
  "bs_submit_campaign_input_reaction_count": "Reaction",
  "bs_submit_campaign_input_view_count": "View",
  "bs_submit_campaign_no_result_photo_yet": "No results photos yet",
  "bs_submit_campaign_social_label": "Social Media",
  "bs_submit_campaign_text_checkbox_interaction": "Add interactive effectiveness metrics",
  "bs_submit_campaign_text_submit_success": "Submitted results successfully",
  "bs_submit_campaign_time_label": "Time",
  "bs_submit_campaign_title_page": "Submit Result",
  "bs_submit_result_link": "Submit Result Link",
  "bs_submit_success_title": "Submit success",
  "bs_submitted_time": "Submitted time",
  "bs_suggest_tooltip_gmv": "Creator's estimated revenue on tiktok in the last 30 days",
  "bs_suggest_tooltip_post_rate": "Creators who don't have a posting rate are Creators who have never run an affiliate before",
  "bs_suggested_list_placeholder_search_user": "Search by creator name",
  "bs_suggested_list_title_table": "Creators register sample products",
  "bs_summary": "Summary",
  "bs_support_format": "Support formats: jpg, jpeg, png, bmp, heic",
  "bs_system_created": "System-created",
  "bs_table_select_total_item": "Selected {{num}} items",
  "bs_tabs_map_video": "There is no live session yet",
  "bs_tabs_pass_video": "There is a live session",
  "bs_tabs_title_info": "Infomation",
  "bs_tabs_title_performance": "Performance",
  "bs_tap_inventory_count": "Inventory",
  "bs_tap_label_content_type": "Content Type",
  "bs_tap_sold_count": "Sold",
  "bs_tap_suggest_content_multiple_popup_approve": "Would you like to approve {{num}} sample request?",
  "bs_tap_suggest_content_multiple_popup_reject": "Do you want to decline {{num}}'s request to receive product samples?",
  "bs_tap_suggest_content_single_popup_approve": "Want to approve a creator's sample request?",
  "bs_tap_suggest_content_single_popup_reject": "Do you want to decline a creator's request to receive sample products?",
  "bs_tap_suggest_title_popup_approve": "Approve creators to receive sample products",
  "bs_tap_suggest_title_popup_reject": "Refuse creators to receive sample products",
  "bs_tap_text_update_multiple_sample": "Enable sample products for {{num}} selected products",
  "bs_tap_total_commission_rate": "Total commission rate",
  "bs_terms_of_use": "Terms Of Use",
  "bs_text_actual": "Actual",
  "bs_text_add_new_slot": "Add new live slot",
  "bs_text_add_range_time": "Add time range",
  "bs_text_age_form": "Age form",
  "bs_text_age_to": "to",
  "bs_text_all_day": "All day",
  "bs_text_all_day_tooltip": "Can be scheduled at any time",
  "bs_text_avg_gmv": "Average GMV",
  "bs_text_busy": "Busy",
  "bs_text_cancel_live": "Cancel live",
  "bs_text_create_new_live_session": "Create new live session",
  "bs_text_custom": "Custom",
  "bs_text_done": "เสร็จสิ้น",
  "bs_text_email": "Email",
  "bs_text_empty_not_select_host": "Please select a host to see the host's registration schedule for {{month}}",
  "bs_text_erorr_time": "End time must be after start time",
  "bs_text_err_input_email": "ที่อยู่อีเมลจำเป็น",
  "bs_text_err_input_password": "รหัสผ่านจำเป็น",
  "bs_text_err_min_length_input_password": "Password must be at least 8 characters",
  "bs_text_err_required_input_verifi_code": "รหัสยืนยันจำเป็น",
  "bs_text_err_type_input_email": "ที่อยู่อีเมลไม่ถูกต้อง",
  "bs_text_estimate": "Estimate",
  "bs_text_estimate_GMV": "Estimate GMV",
  "bs_text_every_week": "Every week",
  "bs_text_everyday": "Every day",
  "bs_text_follower": "Follower",
  "bs_text_forgot_password": "ลืมรหัสผ่าน",
  "bs_text_forgot_password_des": "คุณสามารถทำการรีเซ็ตรหัสผ่านได้สูงสุด 5 ครั้งใน 1 วัน ทุกครั้งละอย่างน้อย 60 วินาที",
  "bs_text_gmv_last_3_month": "GMV last 3 month",
  "bs_text_help_text_custom": "The booking schedule will apply to the dates selected in the calendar",
  "bs_text_help_text_everyday": "The subscription schedule will repeat every day from date {{date}} to date",
  "bs_text_help_text_weekly": "Registration schedule will repeat on",
  "bs_text_help_text_weekly_2": "weekly from {{date}}",
  "bs_text_help_text_weekly_3": "to date {{date}}",
  "bs_text_host_email": "Email host",
  "bs_text_host_name": "Host Name",
  "bs_text_label_done": "Done",
  "bs_text_linked": "Connected",
  "bs_text_linked_account": "Linked Account",
  "bs_text_list_host": "List host",
  "bs_text_list_room": "Manage live room",
  "bs_text_live_infomation": "Change information",
  "bs_text_liveseeson_of_host": "Live session of host",
  "bs_text_need_to_do": "Need to do",
  "bs_text_no": "Never",
  "bs_text_not_connect_shop_code": "Shop not connected yet",
  "bs_text_not_linked": "Not connected",
  "bs_text_not_match_cfm_password": "รหัสผ่านใหม่ที่คุณป้อนไม่ตรง!",
  "bs_text_note_placeholder": "Enter note",
  "bs_text_operating_status": "Operating status",
  "bs_text_passio_link": "Passio Link",
  "bs_text_pending_request": "Pending",
  "bs_text_register_live_month": "Register the live stream schedule for",
  "bs_text_register_live_month_description": "Register a time when you can livestream so the brand can arrange a schedule that suits you",
  "bs_text_register_live_month_text": "Live schedule",
  "bs_text_remain": "Remain",
  "bs_text_repeat": "Repeat",
  "bs_text_required": "Required Information",
  "bs_text_required_input_cfm_password": "โปรดยืนยันรหัสผ่านของคุณ!",
  "bs_text_revenue_from_order": "Revenue from TAP orders",
  "bs_text_sample_product_quantity": "Number of sample products",
  "bs_text_scheduled": "Scheduled",
  "bs_text_search_results": "Search Results",
  "bs_text_seller_center": "Seller Center",
  "bs_text_settings": "Settings",
  "bs_text_shop_code": "Shop code",
  "bs_text_shop_information": "Shop information",
  "bs_text_slot_live": "Live stream slot",
  "bs_text_step": "Step {{step}}",
  "bs_text_sub_brand": "Sub brand",
  "bs_text_target": "Target",
  "bs_text_target_lable_lowercase": "target",
  "bs_text_time_end": "End time",
  "bs_text_time_range": "Time range",
  "bs_text_time_range_tooltip": "Only schedule during the time period you choose",
  "bs_text_time_slot": "Time slot",
  "bs_text_time_start": "Start time",
  "bs_text_total_hour": "Total hour",
  "bs_text_total_session": "Total session",
  "bs_text_total_session_created": "Total session created at",
  "bs_text_upcoming": "Upcoming",
  "bs_text_update_failed": "Update failed",
  "bs_text_update_soon": "We will update soon",
  "bs_text_update_success": "Update success",
  "bs_text_upload_img": "Upload",
  "bs_text_view_more": "View more",
  "bs_th": "Thái Lan",
  "bs_thailand": "Thailand",
  "bs_thailand_branch": "Thailand Branch",
  "bs_this_month": "This month",
  "bs_thousand": "Thousand",
  "bs_tiktok": "Tiktok",
  "bs_tiktok_aff_avg_order_value": "Average unit price",
  "bs_tiktok_aff_cancelled_rate": "Cancellation rate",
  "bs_tiktok_aff_connect": "Connect TiktokShop",
  "bs_tiktok_aff_creator_connect": "Connected Creator",
  "bs_tiktok_aff_detail_sku": "Detail contact",
  "bs_tiktok_aff_list_content": "List content",
  "bs_tiktok_aff_livestream_host": "Host",
  "bs_tiktok_aff_livestream_id": "ID live",
  "bs_tiktok_aff_livestream_monitor": "TAP Live monitor",
  "bs_tiktok_aff_livestream_sesson": "Live session",
  "bs_tiktok_aff_manage_connect_shop": "Manage Shop",
  "bs_tiktok_aff_no_access_view": "Sorry, it is not possible to view the report of this live session because the live session did not go through TAP Campaign",
  "bs_tiktok_aff_no_livestream": "There are no live sessions in progress",
  "bs_tiktok_aff_order_cancelled": "Order has been cancelled",
  "bs_tiktok_aff_orders_report": "TAP Order report",
  "bs_tiktok_aff_suggested": "KOL request",
  "bs_tiktok_aff_tap_sku": "List SKU in TAP campaign",
  "bs_tiktok_aff_trend_chart_index": "Trend chart for each index",
  "bs_tiktok_affiliate_list_sku": "TAP Product performance",
  "bs_tiktok_affiliate_list_suggested": "TAP sample request",
  "bs_tiktok_connect_button_submit": "Connect now",
  "bs_tiktok_connect_description": "To track Ecomobi TAP campaigns that you have signed up for",
  "bs_tiktok_connect_description_step_1_after": "of tiktok and click on",
  "bs_tiktok_connect_description_step_1_before": "Access to",
  "bs_tiktok_connect_description_step_2": "Click vào on menu",
  "bs_tiktok_connect_description_step_3": "Copy",
  "bs_tiktok_connect_instruction_title": "Instructions for getting tiktokshop code",
  "bs_tiktok_connect_message_error": "Shop chưa đăng ký chiến dịch TAP của Ecomobi trên tiktok hoặc mã shop không chính xác. Vui lòng kiểm tra lại",
  "bs_tiktok_connect_message_error_214": "The store has been connected to the system",
  "bs_tiktok_connect_message_success": "Đã kết nối cửa hàng tiktokshop thành công",
  "bs_tiktok_connect_placeholder_shop_code": "Enter shop code",
  "bs_tiktok_connect_title": "Connect your account to tiktokshop",
  "bs_tiktok_counter": "Count Video Tiktok",
  "bs_tiktok_counter_button_export": "Export file",
  "bs_tiktok_counter_button_filter": "Filter",
  "bs_tiktok_counter_button_search": "Search",
  "bs_tiktok_counter_placeholder_search": "By keyword",
  "bs_tiktok_counter_table_title_actions": "Action",
  "bs_tiktok_counter_table_title_author": "KOL name",
  "bs_tiktok_counter_table_title_comments": "Comments Count",
  "bs_tiktok_counter_table_title_description": "Description",
  "bs_tiktok_counter_table_title_likes": "Likes Count",
  "bs_tiktok_counter_table_title_link": "Link Submit",
  "bs_tiktok_counter_table_title_plays": "Plays",
  "bs_tiktok_counter_table_title_reach": "Potential Reach",
  "bs_tiktok_counter_table_title_saves": "Saves",
  "bs_tiktok_counter_table_title_shares": "Shares",
  "bs_tiktok_counter_table_title_update_at": "Last update",
  "bs_tiktok_counter_text_loading_import": "Are importing...",
  "bs_tiktok_counter_title_filter_more": "Optional filter",
  "bs_tiktok_counter_title_modal_edit": "Edit",
  "bs_tiktok_discovery": "Discovery Tiktok Channel",
  "bs_tiktok_discovery_average_comments_count": "Average comments",
  "bs_tiktok_discovery_average_digg_count": "Average like",
  "bs_tiktok_discovery_average_plays_count": "Average view",
  "bs_tiktok_discovery_average_saves_count": "Average saved Bookmarks",
  "bs_tiktok_discovery_average_shares_count": "Share average",
  "bs_tiktok_discovery_placeholder_search": "Search to the account, nickname",
  "bs_tiktok_discovery_sort_by": "Sorted by",
  "bs_tiktok_discovery_table_title_bioLink": "Bio Link",
  "bs_tiktok_discovery_table_title_channel_description": "Channel description",
  "bs_tiktok_discovery_table_title_digg_count": "Likes",
  "bs_tiktok_discovery_table_title_follower_count": "Followers",
  "bs_tiktok_discovery_table_title_following_count": "Followings",
  "bs_tiktok_discovery_table_title_link_channel": "Link channel",
  "bs_tiktok_discovery_table_title_nickname": "Nickname",
  "bs_tiktok_discovery_table_title_plays": "Views",
  "bs_tiktok_discovery_table_title_saves": "Save Bookmarks",
  "bs_tiktok_discovery_table_title_username": "Username",
  "bs_tiktok_discovery_table_title_video_count": "Videos",
  "bs_tiktok_discovery_title_modal_view": "Detailed information",
  "bs_tiktok_manage_connect": "Set up Tiktok Shop",
  "bs_tiktok_shop_error_connect": "The store is connecting with other brands. Please check again",
  "bs_tiktok_shop_success_connect": "Successful store connection",
  "bs_tiktok_text_post_rate": "Post rate",
  "bs_time_end_field_required": "Please select a end time",
  "bs_time_end_label": "End time",
  "bs_time_start_field_required": "Please select a start time",
  "bs_time_start_label": "Start time",
  "bs_time_start_must_before_end_time": "The start time must be before the end time",
  "bs_timeline": "Timeline",
  "bs_title_card_table_list_order": "List of orders  ({{total}})",
  "bs_title_card_table_top_product_live": "Top products",
  "bs_title_create_category": "Add new category",
  "bs_title_create_sub_brand": "Add new sub-brand",
  "bs_title_creator_number": "Creator numbers",
  "bs_title_detail_host": "Detail host",
  "bs_title_download_template_import": "File template import",
  "bs_title_download_template_import_with_type": "File template import {{type}}",
  "bs_title_drag_file": "Click or drag file this area to upload",
  "bs_title_empty_data_search": "No recent items match your search",
  "bs_title_field_create_category": "Name category",
  "bs_title_field_create_sub_brand": "Name sub-brand",
  "bs_title_list_room": "List live room",
  "bs_title_modal_connect_host": "Connect to Brand",
  "bs_title_modal_import_campaign": "Import Campaign",
  "bs_title_modal_import_live_agency": "Import live seasson",
  "bs_title_modal_import_live_result": "Upload Livestream results",
  "bs_title_modal_import_livestream_session": "Import new live sessions",
  "bs_title_modal_import_result": "Import result",
  "bs_title_modal_import_tiktok_counter": "Upload",
  "bs_title_modal_list_kol_selected": "Edit",
  "bs_title_modal_map_data_live": "Select live session for upload results",
  "bs_title_popup_confirm_delete": "Delete record?",
  "bs_title_popup_confirm_delete_campaign": "Delete this item?",
  "bs_title_popup_confirm_delete_sku": "Delete item?",
  "bs_title_search_air_time": "Air Time",
  "bs_title_top_brand": "Top Brand",
  "bs_title_top_channel": "Top channel",
  "bs_title_top_influencer": "Top Influencer",
  "bs_title_top_koc": "Top KOC",
  "bs_title_top_result": "Top 10 Result",
  "bs_title_top_sale_performence": "Top Sale Performance",
  "bs_title_type_import": "Platform",
  "bs_to": "to",
  "bs_to_review": "Review",
  "bs_tool_tip_for_promotion_approved": "Total promotion items approved/ Total slot of all promotion kit",
  "bs_tooltip_colum_gmv_waiting": "GMV đang chờ = Tổng GMV của những đơn Đã hoàn thành và Đang xử lý",
  "bs_tooltip_view_collaborated_creators": "Number of creators who have added the campaign product to their showcase.",
  "bs_tooltip_view_detail": "View Detail",
  "bs_tooltip_view_paid_orders": "Number of paid orders generated via affiliate partner's links, including returned and refunded orders.",
  "bs_tooltip_view_posted_creators": "Number of creators who have posted content with the campaign product.",
  "bs_tooltip_view_revenued_creators": "Number of creators who have generated sales for the campaign product.",
  "bs_tooltip_view_settlemented_orders": "Number of paid orders generated via affiliate partner's links, excluding returned and refunded orders.",
  "bs_tooltip_view_sum_campaigns_filter": "Number of campaigns with videos that meet the filter",
  "bs_tooltip_view_sum_creators_filter": "Number of creators whose videos meet the filter",
  "bs_tooltip_view_sum_paid_orders": "Estimated commission base is the product's sales amount of generated orders on which the commission calculation is based, including returned and refunded orders.",
  "bs_tooltip_view_sum_settlemented_orders": "Actual commission base is the product's settled sales amount on which the commission calculation is based, excluding returned and refunded orders.",
  "bs_top": "Top: over 1.000.000 followers",
  "bs_top_creator": "Top Creator",
  "bs_top_not_des": "Top",
  "bs_top_result_button_view_all": "View All",
  "bs_top_selling_product": "Top best-selling products",
  "bs_top_video_title": "Top video",
  "bs_total": "Total",
  "bs_total_budget": "Total bubget",
  "bs_total_budget_is_required": "The Total bubget field is required",
  "bs_total_follower": "Followers number",
  "bs_total_follower_is_required": "The Followers field is required",
  "bs_total_followers": "Followers",
  "bs_total_item": "Total Item",
  "bs_total_items_numer": "Total {{number}} items",
  "bs_tour_description_step_1": "Connect your account to your TikTok Shop store to sync your TikTok Affiliate data and take advantage of TAP features!",
  "bs_tour_description_step_10": "Manage a list of all your orders from all platforms",
  "bs_tour_description_step_2": "Monitor your Sales Progress and Effectiveness with Visual analytics",
  "bs_tour_description_step_3": "Manage the list and Review sample requests from Creators for products you’re running TikTok Affiliate with Ecomobi",
  "bs_tour_description_step_4": "Manage your TikTok Affiliate products here! Follow sample products config and see how creators are performing.",
  "bs_tour_description_step_5": "Track and analyze livestream performance on your channel. Easily monitor host success, find high-performing livestreams, and make informed decisions.",
  "bs_tour_description_step_6": "Track orders from live streaming sessions with TAP product links on creator’s channel in real-time",
  "bs_tour_description_step_7": "Manage the list of live sessions on your channel. All livestream-related tasks, including scheduling hosts, monitoring progress, uploading live session results, and evaluating sessions, are handled here.",
  "bs_tour_description_step_8": "Monitor a comprehensive report on all your orders. Quickly identify best-selling products, product lines, high-performance KOLs, and effective posts.",
  "bs_tour_description_step_9": "Review the TikTok affiliate product order report from multiple angles to conduct in-depth analysis and make accurate assessments",
  "bs_tour_description_step_manual": "For a tutorial on how to use the system, please press the “Get Started” button.",
  "bs_tour_description_step_welcome": "Thank you and welcome to our platform that supports brands in managing and operating Marketing and Sales activities across social media platforms and e-commerce marketplaces. Explore now by clicking “Get Started”!",
  "bs_tour_title_step_1": "Connect Shop",
  "bs_tour_title_step_10": "Order List Management",
  "bs_tour_title_step_2": "Dashboard",
  "bs_tour_title_step_3": "Sample Product Request",
  "bs_tour_title_step_4": "Performance Report for TAP Products",
  "bs_tour_title_step_5": "Livestream Effectiveness Report",
  "bs_tour_title_step_6": "Live Session Monitoring",
  "bs_tour_title_step_7": "Livestream Management",
  "bs_tour_title_step_8": "Order Report for Multiple Platforms",
  "bs_tour_title_step_9": "TAP Orders Report",
  "bs_tour_title_step_manual": "Product Tour",
  "bs_tour_title_step_welcome": "Welcome to Brand Saas",
  "bs_travel": "Travel",
  "bs_try_it_now": "Try it now",
  "bs_twitter": "Twitter",
  "bs_unlimited": "Unlimited",
  "bs_upload_image_format": "The photo is not in the correct format",
  "bs_upload_image_max_size": "Please upload photos with a maximum capacity of 4M",
  "bs_user_created": "User-created",
  "bs_username": "Username",
  "bs_username_is_required": "Username is required",
  "bs_value": "Value",
  "bs_verification_code_incorrect": "รหัสยืนยันไม่ถูกต้อง",
  "bs_vi": "Tiếng Việt",
  "bs_video": "Video",
  "bs_video_booking": "Video booking",
  "bs_video_job_create": "Create video job",
  "bs_video_job_edit": "Edit video job",
  "bs_video_job_field_required": "This field is required",
  "bs_video_job_text_buy_now": "Buy now",
  "bs_video_job_text_buy_video": "Buy video",
  "bs_video_job_text_check_and_download_video": "Accept and download videos for use",
  "bs_video_job_text_check_video": "Video acceptance",
  "bs_video_job_text_choose_kol": "Congratulations on choosing your KOL",
  "bs_video_job_text_choose_kol_title": "Choose KOL",
  "bs_video_job_text_congratulation_first_step": "Congratulations on completing the first step",
  "bs_video_job_text_congratulation_video": "Congratulations on completing the video ordering stream with Passiono",
  "bs_video_job_text_create_order": "Create orders",
  "bs_video_job_text_create_order_kol_success": "View the applied KOLs and choose the KOL you want",
  "bs_video_job_text_create_order_success": "You have successfully created your first order",
  "bs_video_job_text_description_for_kol": "Describe to KOLs your video requirements",
  "bs_video_job_text_guide_book_video": "Instructions for booking videos",
  "bs_video_job_text_receive_video": "Receive videos, moderate them, and give feedback if editing is needed",
  "bs_video_job_text_receive_video_title": "Receive video",
  "bs_video_job_text_start_buy_video": "Start by purchasing the type of video you want to order!",
  "bs_video_job_text_steps_to_book_video": "Steps to book a video",
  "bs_video_live": "Video live",
  "bs_video_package_button_checkout": "Payment",
  "bs_video_package_button_contact": "Contact",
  "bs_video_package_text_cart": "Order",
  "bs_video_package_text_cart_title": "Order",
  "bs_video_package_text_company_name": "Company name",
  "bs_video_package_text_contact": "Contact us",
  "bs_video_package_text_contact_price": "Contact us to receive a price",
  "bs_video_package_text_discount_code": "Discount",
  "bs_video_package_text_duration": "Maximum video time",
  "bs_video_package_text_email": "Email",
  "bs_video_package_text_empty": "There are currently no video packages available",
  "bs_video_package_text_empty_cart": "There are no video packages yet",
  "bs_video_package_text_hotline": "Hotline",
  "bs_video_package_text_payment_methods": "Payment via payment gateways",
  "bs_video_package_text_subtotal": "Subtotal",
  "bs_video_package_text_total": "Total",
  "bs_video_package_text_video": "Video",
  "bs_video_package_text_zalo": "Zalo",
  "bs_video_report_title": "Video report",
  "bs_video_sample_text": "Example video",
  "bs_vietNam_branch": "VietNam Branch",
  "bs_vietnam": "Vietnam",
  "bs_view_content": "View Detail",
  "bs_view_detail": "View Detail",
  "bs_view_on_tiktok": "View on Tiktok",
  "bs_violate": "Violate",
  "bs_whole_system": "Whole system",
  "bs_win_clients": "win clients",
  "bs_you_label": "You",
  "bs_your_social_campaigns": "Your Social Campaigns",
  "bs_youtube": "Youtube",
  "pc_error_image_max_size": "ขนาดไฟล์สูงสุด: {{size}}",
  "search_campaign_bookings_v2": "Search by ID, name",
  "ss_brand_booking_title_modal_warning": "Warning Detail",
  "ss_placeholder_select_sku": "Please select Sku",
  "ss_placeholder_select_sub_brand": "Please select Sub-brand",
  "ss_result_booking_text_warning_button": "Warning",
  "status_all": "All",
  "status_campagin_end": "Ended",
  "status_campagin_not_start": "Not started yet",
  "status_campagin_running": "Running",
  "status_complete": "Completed",
  "status_processing": "Processing",
  "sub_brand_report": "Sub-brand Report",
  "submit_campaign_input_channel_link": "Link Channel",
  "submit_campaign_input_channel_link_placeholder": "Enter the channel link",
  "submit_campaign_input_reach_count": "Reach",
  "text_custom_range": "Custom Range",
  "text_last_month": "Last Month",
  "text_last_n_day": "Last {{n}} Days",
  "text_this_month": "This Month",
  "text_today": "Today",
  "text_yesterday": "Yesterday",
  "vi_orders": "Orders"
} 
} 
export default th
import { Image } from 'antd';
import TranslatedText from '../TranslatedText';
import { ErrorPageIcon } from '../icons';
import ButtonCustom from '../ButtonCustom';
import { DASHBOARD_ROUTES } from '@features/dashboard/config/route';

const PASSIO_LOGO = '/images/koc-information/passio-favicon.png';

const ErrorPage = () => {
  const handleToHome = () => {
    window.location.href = DASHBOARD_ROUTES.index;
  };

  return (
    <div className='w-screen h-screen flex justify-center items-center bg-white flex-col gap-8'>
      <div className='w-16'>
        <Image src={PASSIO_LOGO} preview={false} />
      </div>
      <TranslatedText variant='heading5' className='!text-gray-900' translationKey='some_thing_wrong' />
      <ErrorPageIcon />
      <ButtonCustom onClick={handleToHome}>
        <TranslatedText className='!text-white' variant='body4' translationKey='return_home' />
      </ButtonCustom>
    </div>
  );
};

export default ErrorPage;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { InfoCampaign } from '@features/tiktok-affiliate/tiktokAffiliateInterface';

interface TiktokState {
  isConnect: boolean;
  isLoading: boolean;
  shop_code: string;
  campaign_active: InfoCampaign | undefined;
}

const initialState: TiktokState = {
  isConnect: false,
  isLoading: false,
  shop_code: '',
  campaign_active: undefined,
};
const TiktokAffSlice = createSlice({
  name: 'TiktokAffiliateSlice',
  initialState,
  reducers: {
    setConnect: (state: TiktokState, action: PayloadAction<boolean>) => {
      state.isConnect = action.payload;
    },
    setIsLoading: (state: TiktokState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShopCode: (state: TiktokState, action: PayloadAction<string>) => {
      state.shop_code = action.payload;
    },
    setCampaignActive: (state: TiktokState, action: PayloadAction<InfoCampaign | undefined>) => {
      state.campaign_active = action.payload;
    },
  },
});
export const { setConnect, setIsLoading, setShopCode, setCampaignActive } = TiktokAffSlice.actions;
export const selectConnectTiktok = (state: RootState) => state.tiktokAffiliate.isConnect;
export const selectIsLoadingConnect = (state: RootState) => state.tiktokAffiliate.isLoading;
export const selectShopCode = (state: RootState) => state.tiktokAffiliate.shop_code;
export const selectCampaign = (state: RootState) => state.tiktokAffiliate.campaign_active;
const TiktokAffReducer = TiktokAffSlice.reducer;
export default TiktokAffReducer;

import { Button, ButtonProps } from 'antd';
import styles from './style.module.scss';
import { APP_INPUT_HEIGHT } from '@components/shared/app-inputs';

type IProps = ButtonProps & {
  onlyIcon?: boolean;
};

const ButtonCustom = ({ children, type, style, className, icon, onlyIcon, ...props }: IProps) => (
  <Button
    type='primary'
    style={{ backgroundColor: '#ee3446', height: APP_INPUT_HEIGHT, ...style }}
    className={`${styles.buttonCustom} ${className}`}
    icon={icon && <div className={`${onlyIcon ? '' : 'mr-2'}`}>{icon}</div>}
    {...props}
  >
    {children}
  </Button>
);

export default ButtonCustom;
